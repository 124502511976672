/** @jsxImportSource @emotion/react */
import { AlertColor, Alert as MuiAlert, Snackbar as MuiSnackbar, Typography } from '@mui/material'
import Slide, { SlideProps } from '@mui/material/Slide'
import { useTheme } from '@mui/material/styles'

import { snackbarContainer } from './Snackbar.styles'

export interface SnackbarProps {
  message: string
  messageType: AlertColor
  removeSnackbar: () => void
  className?: string
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />
}

export const Snackbar = ({ message, messageType, removeSnackbar, className }: SnackbarProps) => {
  const theme = useTheme()

  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return
    removeSnackbar()
  }

  return (
    <MuiSnackbar
      open
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
      className={className}
    >
      <MuiAlert onClose={handleClose} severity={messageType} css={snackbarContainer({ theme })}>
        <Typography>{message}</Typography>
      </MuiAlert>
    </MuiSnackbar>
  )
}
