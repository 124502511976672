/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  Box,
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material'

import { Icon } from '../Icon'

export interface IconTypographyProps extends MuiTypographyProps {
  icon: Icon
}

export const IconTypography = ({ icon, ...rest }: IconTypographyProps) => {
  return (
    <Box
      css={css`
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 4px;
      `}
    >
      <Icon iconName={icon} size="small" />
      <MuiTypography {...rest} variant={'body1'} data-testid="typography" />
    </Box>
  )
}
