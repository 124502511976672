/** @jsxImportSource @emotion/react */

import { useEffect, useState } from 'react'
import { Modal } from '@src/components/Atoms/Modal'
import { Button } from '@src/components/Atoms/Button'
import { Box } from '@mui/material'
import { TutorialRunner, TutorialStepData } from '../TutorialRunner'
import { buttonBox, contentBox, exitButton, heading, menu, modal } from './TutorialMenu.styles'
import { useTutorialContext } from '@contexts/TutorialContext'
import { KeyboardReturnRounded } from '@mui/icons-material'

// Define the props for the Tutorial component
interface TutorialMenuProps {
  tutorialModules: {
    [key: string]: {
      icon: React.ReactNode
      steps: TutorialStepData[]
    }
  }
  title: string
  welcomeText: string | JSX.Element
  congratulationsText: string | JSX.Element
}

const addModuleTitlesToSteps = (
  modules: TutorialMenuProps['tutorialModules'],
): {
  [key: string]: TutorialStepData[]
} => {
  return Object.keys(modules).reduce(
    (acc, module) => ({
      ...acc,
      [module]: modules[module].steps.map((step) => ({
        ...step,
        moduleTitle: module,
      })),
    }),
    {},
  )
}

export const TutorialMenu = ({
  tutorialModules,
  welcomeText,
  title,
  congratulationsText,
}: TutorialMenuProps) => {
  const { setTutorialActive } = useTutorialContext()

  const [tutorialModule, setTutorialModule] = useState<string>('_initial' as string)
  const [tutorialSteps, setTutorialSteps] = useState<TutorialStepData[]>([])

  useEffect(() => {
    if (tutorialModule == '_full') {
      setTutorialSteps(
        Object.values(addModuleTitlesToSteps(tutorialModules)).flatMap((steps) => steps),
      )
    } else if (tutorialModules[tutorialModule]) {
      setTutorialSteps(addModuleTitlesToSteps(tutorialModules)[tutorialModule])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorialModule])

  useEffect(() => {
    setTutorialModule('_initial')
  }, [])

  const tutorialHandleExit = () => {
    if (!['_initial', '_conclusion'].includes(tutorialModule)) {
      setTutorialModule('_initial')
      setTutorialActive(false)
    }
  }
  const tutorialHandleComplete = () => {
    setTutorialModule('_conclusion')
  }

  return (
    <>
      <Modal open={tutorialModule == '_initial'}>
        <Box css={modal}>
          <Box css={contentBox}>
            <Box css={heading}>
              <img src="/images/tutorial/RiskTabIcon.png" />
              <h1>{title}</h1>
              <Box
                css={exitButton}
                role="button"
                onClick={() => {
                  setTutorialActive(false)
                }}
              >
                ×
              </Box>
            </Box>
            {welcomeText}
            <br />
            Please choose your preferred option or proceed to the full tutorial by selecting
            &quot;Next&quot;.
            <Box css={menu}>
              {Object.keys(tutorialModules).map((module) => (
                <Button key={module} onClick={() => setTutorialModule(module)}>
                  <Box className="icon">{tutorialModules[module].icon}</Box> {module}
                </Button>
              ))}
            </Box>
          </Box>
          <Box css={buttonBox}>
            <Button onClick={() => setTutorialModule('_full')}>Next</Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={tutorialModule == '_conclusion'}>
        <Box css={modal}>
          <Box css={contentBox}>
            <Box css={heading}>
              <img src="/images/tutorial/RiskTabIcon.png" />
              <h1>Congratulations!</h1>
              <Box
                css={exitButton}
                role="button"
                onClick={() => {
                  setTutorialActive(false)
                }}
              >
                ×
              </Box>
            </Box>
            {congratulationsText}
            <Box css={menu}>
              <Button onClick={() => setTutorialModule('_initial')}>
                <Box className="icon">
                  <KeyboardReturnRounded />
                </Box>
                Try Another Tutorial
              </Button>
            </Box>
          </Box>
          <Box css={buttonBox}>
            <Button
              onClick={() => {
                setTutorialActive(false)
              }}
            >
              Exit
            </Button>
          </Box>
        </Box>
      </Modal>
      <TutorialRunner
        enabled={!['_initial', '_conclusion'].includes(tutorialModule)}
        tutorialSteps={tutorialSteps}
        handleExit={tutorialHandleExit}
        handleComplete={tutorialHandleComplete}
      />
    </>
  )
}
