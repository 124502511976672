import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const gaugeContentCard = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  min-height: 240px;
  max-height: 340px;

  min-width: 60px;
  max-width: 200px;

  gap: ${theme.spacing(2)};
`

export const gaugeContentTitle = css`
  display: flex;
  align-items: start;
  justify-content: center;
  text-align: center;

  min-height: 60px;
`

export const gaugeContentTitleLabel = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
`

export const gaugeContentDescriptionLabel = css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
`

export const gaugeContentDescription = css`
  text-align: center;

  display: flex;
  align-items: start;
  justify-content: center;

  height: auto;
  min-height: 60px;
  flex: 1;
`
