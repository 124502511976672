/** @jsxImportSource @emotion/react */
import {
  IconButton,
  Tooltip,
  Typography,
  TextField,
  Box,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Autocomplete,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'

import { MapLayer, MapLayerType } from '@redux/map/mapSlice'
import { BASE_API_URL } from '@src/app-constants'
import { Icon } from '@src/components/Atoms/Icon'
import { AssetDialogData } from '../AddLayersDialog.types'
import {
  assetInternalAccordionIcon,
  assetInternalAccordionTitle,
  assetLayersFormAccordions,
  assetLayersFormContainer,
  assetLayersFormContent,
  assetLayersFormInternalAccordion,
  conditionalAssetItemStyling,
} from './AssetLayersForm.styles'
import { UiclAccordion } from '@src/components/Atoms/Accordion/UiclAccordion'
import { firstLetterToUpperCase } from '@src/utils/strings.utils'
import { autocompleteFields } from '../AddLayersDialog.styles'
import { compareStrings } from '@src/components/Molecules/Charts/utils/utils'
import { useSnackbars } from '@contexts/SnackbarContext'
import { MoreInformationModal } from '@src/components/Organisms/Modals/MoreInformationModal'
import { IconTypography } from '@src/components/Atoms/IconTypography'

export type AssetLayersFormProps = {
  assetDialogData: AssetDialogData
  onSelectAssets: (selectedAssets: MapLayer[]) => void
  onRemoveLayer: (layer: MapLayer, type: MapLayerType) => void
  selectedAssets: MapLayer[]
  selectedHazards: MapLayer[]
}

const flattenAssetDialogData = (assetDialogData: AssetDialogData): MapLayer[] => {
  const toReturn: MapLayer[] = []
  const handledIds: { [key: string]: boolean } = {}

  Object.keys(assetDialogData).map((assetMenuKey) => {
    if (!(assetMenuKey in assetDialogData)) return
    if (!('groups' in assetDialogData[assetMenuKey])) return
    if (!assetDialogData[assetMenuKey].groups.length) return

    assetDialogData[assetMenuKey].groups.map((group) => {
      group.assets.map((assetDetail) => {
        if (handledIds[assetDetail.tilesets[0].id]) return

        toReturn.push(assetDetail)
        handledIds[assetDetail.tilesets[0].id] = true
      })
    })
  })

  toReturn.sort((a, b) => {
    return compareStrings(a.display_name, b.display_name)
  })

  return toReturn
}

export const AssetLayersForm = ({
  assetDialogData,
  selectedAssets,
  selectedHazards,
  onSelectAssets,
  onRemoveLayer,
}: AssetLayersFormProps) => {
  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()
  const flatAssetList = flattenAssetDialogData(assetDialogData)

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchFoundMapLayer, setSearchFoundMapLayer] = useState<MapLayer | null>(null)

  const hasError = useMemo(() => {
    const errors = Object.keys(assetDialogData).map((assetMenuKey) => {
      if (!(assetMenuKey in assetDialogData)) {
        // eslint-disable-next-line no-console
        console.log(
          `No assetMenuKey "${assetMenuKey}" in assetDialogData: ${JSON.stringify(
            assetDialogData,
          )}`,
        )
        return true
      }
      if (!('groups' in assetDialogData[assetMenuKey])) {
        // eslint-disable-next-line no-console
        console.log(
          `No "groups" in assetDialogData[assetMenuKey]: ${JSON.stringify(
            assetDialogData[assetMenuKey],
          )}`,
        )
        return true
      }
      if (!assetDialogData[assetMenuKey].groups.length) {
        // eslint-disable-next-line no-console
        console.log(
          `Groups in assetDialogData[assetMenuKey] is empty: ${JSON.stringify(
            assetDialogData[assetMenuKey],
          )}`,
        )
        return true
      }

      assetDialogData[assetMenuKey].groups.map((group) => {
        group.assets.map((assetDetail) => {
          if (!assetDetail.tilesets[0].id) {
            // eslint-disable-next-line no-console
            console.log(`No tilesets[0].id in assetDetail: ${JSON.stringify(assetDetail)}`)
            return true
          }
          return false
        })
      })

      return false
    })

    return errors.some((error) => error)
  }, [assetDialogData])

  const [informationDetailsModal, setInformationDetailsModal] = useState({
    isOpen: false,
    helpFileName: '',
  })

  const cannotAddCascadingLayer = selectedHazards && selectedHazards.length > 1

  const isAssetSelected = (asset: MapLayer) => {
    const existingAssets = selectedAssets

    const isAlreadySelected = existingAssets.some(
      (selectedAsset) => selectedAsset.tilesets[0].id === asset.tilesets[0].id,
    )
    return isAlreadySelected
  }

  const selectAsset = (selectedAsset: MapLayer) => {
    const foundAssets = selectedAssets.find((asset) => {
      return asset.type === selectedAsset.type
    })
    if (foundAssets) {
      onRemoveLayer(selectedAsset, 'asset')
      return
    }

    if (selectedAsset.is_cascading && selectedHazards && selectedHazards.length > 1) {
      displaySnackbar({
        message:
          'While multiple hazard layers are active, you cannot add a cascading layer. Please remove additional hazard layers before adding a cascading layer.',
        type: 'error',
      })
      return
    }

    const formattedSelectedAsset = {
      ...selectedAsset,
      visible: true,
      interactivityDisabled: false,
      layerType: 'asset' as MapLayerType,
    }
    onSelectAssets([formattedSelectedAsset])
  }

  if (hasError) {
    return (
      <Typography variant="subtitle2" align="center" marginTop={1}>
        No element available
      </Typography>
    )
  }

  return (
    <>
      <Box css={assetLayersFormContainer({ theme })}>
        <Autocomplete
          css={autocompleteFields({ theme })}
          autoHighlight
          freeSolo // Stops making it look like a drop down however this means values can either be MapLayers or strings
          clearOnBlur
          inputValue={searchTerm} // Because of freeSolo the inputValue is a string
          value={searchFoundMapLayer} // while the value itself can be a string or MapLayer
          options={flatAssetList}
          isOptionEqualToValue={(option, value) =>
            option.id == value.id && option.type == value.type
          }
          getOptionLabel={(option) => (typeof option == 'string' ? option : option.display_name)}
          getOptionKey={(option) => (typeof option == 'string' ? option : option.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search"
              placeholder="Search"
              autoComplete="off"
              autoFocus
            />
          )}
          renderOption={(props, option, _state, ownerState) => {
            const { key, ...optionProps } = props
            return (
              <li key={key} {...optionProps}>
                {isAssetSelected(option) ? (
                  <b>{ownerState.getOptionLabel(option)}</b>
                ) : (
                  ownerState.getOptionLabel(option)
                )}
              </li>
            )
          }}
          onChange={(_event, asset) => {
            if (asset && typeof asset !== 'string') {
              // We found an actual MapLayer and because of freeSolo
              // it's not some random string which doesn't match anything
              selectAsset(asset)
              setSearchFoundMapLayer(null)
            }
          }}
          onInputChange={(_e, newInputValue, reason) => {
            // Clear the input when an item is selected
            if (reason == 'reset') setSearchTerm('')
            else setSearchTerm(newInputValue) // without this the text field doesn't update when typing
          }}
        />

        <Box css={assetLayersFormContent()}>
          <Box css={assetLayersFormAccordions({ theme })}>
            {Object.keys(assetDialogData).map((assetMenuKey) => (
              <UiclAccordion
                key={assetMenuKey}
                header={
                  <IconTypography icon={assetDialogData[assetMenuKey].icon}>
                    {assetDialogData[assetMenuKey].title}
                  </IconTypography>
                }
                lazyContent
                body={
                  <Box css={assetLayersFormInternalAccordion({ theme })}>
                    {assetDialogData[assetMenuKey].groups.map((group) =>
                      // items that are ungrouped are displayed without an accordion
                      group.groupName === 'Ungrouped' ? (
                        <List key={group.groupName} disablePadding>
                          {group.assets.map((asset) => {
                            return (
                              <ListItemButton
                                selected={isAssetSelected(asset)}
                                key={`${group.groupName}-${asset.type}-${asset.display_name}`}
                                onClick={() => selectAsset(asset)}
                                sx={{ paddingLeft: '8.6px' }}
                                css={conditionalAssetItemStyling({
                                  theme,
                                  disallowed: !!(cannotAddCascadingLayer && asset.is_cascading),
                                })}
                              >
                                <ListItemIcon css={assetInternalAccordionIcon}>
                                  <IconTypography
                                    icon={firstLetterToUpperCase(asset.icon) as Icon}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={asset.display_name} />
                                <ListItemIcon css={assetInternalAccordionIcon}>
                                  {asset.helpFileName && (
                                    <Tooltip title={asset.display_name}>
                                      <IconButton
                                        size="small"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          setInformationDetailsModal({
                                            isOpen: true,
                                            helpFileName: asset.helpFileName,
                                          })
                                        }}
                                      >
                                        <Icon
                                          iconName="Info"
                                          colour={theme.palette.grey[500]}
                                          size="small"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </ListItemIcon>
                              </ListItemButton>
                            )
                          })}
                        </List>
                      ) : (
                        <UiclAccordion
                          key={group.groupName}
                          header={
                            <IconTypography css={assetInternalAccordionTitle} icon={group.icon}>
                              {group.groupName}
                            </IconTypography>
                          }
                          body={
                            <List disablePadding>
                              {group.assets.map((asset) => {
                                return (
                                  <ListItemButton
                                    selected={isAssetSelected(asset)}
                                    key={`${group.groupName}-${asset.type}-${asset.display_name}`}
                                    onClick={() => selectAsset(asset)}
                                    css={conditionalAssetItemStyling({
                                      theme,
                                      disallowed: !!(cannotAddCascadingLayer && asset.is_cascading),
                                    })}
                                  >
                                    <ListItemIcon css={assetInternalAccordionIcon}>
                                      <IconTypography
                                        icon={firstLetterToUpperCase(asset.icon) as Icon}
                                      />
                                    </ListItemIcon>
                                    <ListItemText
                                      sx={{ paddingLeft: theme.spacing(1) }}
                                      primary={asset.display_name}
                                    />
                                    <ListItemIcon css={assetInternalAccordionIcon}>
                                      {asset.helpFileName && (
                                        <Tooltip title={asset.display_name}>
                                          <IconButton
                                            size="small"
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              setInformationDetailsModal({
                                                isOpen: true,
                                                helpFileName: asset.helpFileName,
                                              })
                                            }}
                                          >
                                            <Icon
                                              iconName="Info"
                                              colour={theme.palette.grey[500]}
                                              size="small"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </ListItemIcon>
                                  </ListItemButton>
                                )
                              })}
                            </List>
                          }
                        ></UiclAccordion>
                      ),
                    )}
                  </Box>
                }
              ></UiclAccordion>
            ))}
          </Box>
        </Box>
      </Box>
      {informationDetailsModal.isOpen &&
        createPortal(
          <MoreInformationModal
            baseURL={BASE_API_URL}
            helpFileName={informationDetailsModal.helpFileName}
            isOpen={informationDetailsModal.isOpen}
            handleClose={() =>
              setInformationDetailsModal({
                isOpen: false,
                helpFileName: '',
              })
            }
          />,
          document.body,
        )}
    </>
  )
}
