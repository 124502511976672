/** @jsxImportSource @emotion/react */
import { Modal as MuiModal, ModalProps as MuiModalProps } from '@mui/material'
import Paper from '@mui/material/Paper'

import { paperMainClass } from './Modal.styles'

export type ModalProps = { elevation?: number } & MuiModalProps

export const Modal = ({ elevation = 24, children, ...rest }: ModalProps) => {
  return (
    <MuiModal data-testid="modal" {...rest} style={{ display: 'flex', flex: '1 1 auto' }}>
      <Paper elevation={elevation} css={paperMainClass} style={{ width: 'max-content' }}>
        {children}
      </Paper>
    </MuiModal>
  )
}
