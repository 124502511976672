/** @jsxImportSource @emotion/react */
import { Box, useTheme } from '@mui/material'

import { Icon } from '../Icon'
import {
  fillDashoffset,
  fillMainClass,
  fillPathD,
  fillPathDasharray,
  fillStrokeLinecap,
  lineDashoffset,
  linePathD,
  linePathDasharray,
  lineStrokeLinecap,
  swatchIconParent,
  swatchLineParent,
} from './Swatch.styles'

export type SwatchSize = 'small' | 'medium' | 'large'

export type SwatchType = 'fill' | 'line' | 'icon' | 'point' | 'polygon'

export type SwatchStyle =
  | 'normal'
  | 'dashed' // LINE STYLES
  | 'dotted'
  | 'solid'
  | 'double'
  | 'hashed' // FILL STYLES
  | 'diagonal_stripes'
  | 'horizontal_stripes'
  | 'vertical_stripes'
  | 'hatching'
  | 'dotted'
  | 'checked'
  | 'border'

export type SwatchStyleWeight = 'light' | 'normal' | 'heavy'

export type SwatchItem = {
  color?: string
  secondary_color?: string
  type?: SwatchType
  style?: SwatchStyle
  weight?: SwatchStyleWeight
  size?: SwatchSize
  icon?: Icon
}

export type SwatchProps = {
  item: SwatchItem
  isTop?: boolean
  isBottom?: boolean
}

export const SwatchWeights = {
  light: 1,
  normal: 2,
  heavy: 3,
}

export const Swatch = ({ item, isTop = false, isBottom = false }: SwatchProps) => {
  const theme = useTheme()

  if (!item.style) item.style = 'normal'
  if (!item.size) item.size = 'medium'
  if (!item.weight) item.weight = 'normal'
  if (!item.type) item.type = 'fill'
  if (!item.color) item.color = theme.palette.secondary.main

  if (item.type === 'fill') {
    if (item.style != 'normal' && item.secondary_color == undefined) {
      item.secondary_color = theme.palette.primary.main
    }
    return (
      <Box data-testid="Swatch" css={fillMainClass(item, theme, isTop, isBottom)}>
        {item.style != 'normal' && (
          <svg>
            <g stroke={item.secondary_color} strokeWidth={SwatchWeights[item.weight]}>
              <path
                strokeDasharray={fillPathDasharray(item)}
                d={fillPathD(item)}
                strokeLinecap={fillStrokeLinecap(item)}
                strokeDashoffset={fillDashoffset(item)}
              />
            </g>
          </svg>
        )}
      </Box>
    )
  } else if (item.type === 'line') {
    return (
      <Box data-testid="Swatch" css={swatchLineParent(item, theme)}>
        <svg>
          <g stroke={item.color} strokeWidth={SwatchWeights[item.weight] * 1.5}>
            <path
              strokeDasharray={linePathDasharray(item)}
              d={linePathD(item)}
              strokeLinecap={lineStrokeLinecap(item)}
              strokeDashoffset={lineDashoffset(item)}
            />
          </g>
        </svg>
      </Box>
    )
  } else {
    // Assume it's an icon
    let icon_name: Icon
    if (item.type == 'point') {
      icon_name = 'Pin'
    } else if (item.type == 'polygon') {
      icon_name = 'AssetArea'
    } else if (item.icon == undefined) {
      icon_name = 'Warning'
    } else {
      icon_name = item.icon
    }

    return (
      <Box data-testid="Swatch" css={swatchIconParent(item, theme)}>
        <Icon iconName={icon_name} size={item.size} colour={item.color}></Icon>
      </Box>
    )
  }
}
