import { Icon } from '@src/components/Atoms/Icon'
import { theme } from '@styles/theme'

export function getIconColour({ active, disabled }: { active: boolean; disabled: boolean }) {
  if (active) {
    return theme.palette.white.main
  }
  if (disabled) {
    return theme.palette.grey[300]
  }
  return theme.palette.grey[500]
}

export function getDivisionIconByString({
  iconName,
  colour,
  size = 'medium',
}: {
  iconName: string
  colour: string
  size?: 'small' | 'medium' | 'large'
}) {
  switch (iconName) {
    case 'Corporation':
      return <Icon iconName="Corporation" colour={colour} size={size} />
    case 'Horticulture':
      return <Icon iconName="Horticulture" colour={colour} size={size} />
    case 'Proteins':
      return <Icon iconName="Proteins" colour={colour} size={size} />
    case 'Logistics':
      return <Icon iconName="Logistics" colour={colour} size={size} />

    default:
      return <Icon iconName="Help" colour={colour} />
  }
}

export function getLandingPageLinkIconByString(iconName: string) {
  switch (iconName) {
    case 'Review':
      return <Icon iconName="Monitor" colour={theme.palette.primary.main} />
    case 'HorizonScan':
      return <Icon iconName="HorizonScan" colour={theme.palette.primary.main} />
    case 'Bulb':
      return <Icon iconName="Bulb" colour={theme.palette.primary.main} />
    case 'ArrowLeftRight':
      return <Icon iconName="ArrowLeftRight" colour={theme.palette.primary.main} />
    case 'Pin':
      return <Icon iconName="Pin" colour={theme.palette.primary.main} />

    default:
      return <Icon iconName="Help" colour={theme.palette.primary.main} />
  }
}
