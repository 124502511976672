/** @jsxImportSource @emotion/react */
import { Feature, FeatureCollection, Point, Polygon, Position } from 'geojson'
import { MapLayerMouseEvent } from 'mapbox-gl'
import * as turf from '@turf/turf'
import mapboxgl from 'mapbox-gl'
import { UpdateDrawAreasProps } from '@contexts/MapContext/MapContext.types'
import { DrawArea } from '@redux/map/mapSlice'
import { Box, IconButton, useTheme, Typography } from '@mui/material'
import { Clear } from '@mui/icons-material'
import {
  mapTool,
  mapToolClearButton,
  mapToolContent,
  mapToolHeader,
  mapToolHeaderTitle,
  mapToolHint,
} from './MapToolsPortal.styles'
import { formatArea, formatDistance } from './MapTools'
import { Snackbar } from '@contexts/SnackbarContext'

export type SelectionArea = {
  area: string | null
  perimeter: string | null
}

export default class SelectionTool {
  static activateSelectionTool(
    map: mapboxgl.Map,
    updateDrawAreas: (props: UpdateDrawAreasProps) => void,
    setIsSelecting: React.Dispatch<React.SetStateAction<boolean>>,
    setHasSelection: React.Dispatch<React.SetStateAction<boolean>>,
    displaySnackbar: (snackbar: Snackbar) => void,
    setSelectionArea: React.Dispatch<React.SetStateAction<SelectionArea>>,
  ) {
    featureCollection.features = []
    setSelecting = setIsSelecting
    setItHasSelection = setHasSelection
    updateTheDrawAreas = updateDrawAreas
    displayTheSnackbar = displaySnackbar
    map.getCanvas().style.cursor = 'crosshair'
    setTheSelectionArea = setSelectionArea

    SelectionTool.addSource(map)

    if (!map.getLayer('selectionToolPointsLayer')) {
      map.addLayer({
        id: 'selectionToolPointsLayer',
        type: 'circle',
        source: 'selectionToolSource',
        paint: {
          'circle-radius': 5,
          'circle-color': '#0B2948',
        },
        filter: ['in', '$type', 'Point'],
      })
    }

    if (!map.getLayer('selectionToolLastPointLayer')) {
      map.addLayer({
        id: 'selectionToolLastPointLayer',
        type: 'circle',
        source: 'selectionToolSource',
        paint: {
          'circle-radius': 7,
          'circle-stroke-color': '#00f',
          'circle-stroke-width': 2,
          'circle-opacity': 0,
        },
        filter: ['==', ['get', 'last'], true],
      })
    }

    SelectionTool.addToolLayers(map)

    map.on('click', onMouseClick)

    map.on('mousemove', 'selectionToolAreaLayer', onMouseMoveArea)
    map.on('mouseleave', 'selectionToolAreaLayer', onMouseLeave)
    map.on('mousedown', 'selectionToolAreaLayer', onMouseDownArea)

    map.on('mouseenter', 'selectionToolPointsLayer', onMouseEnterPoint)
    map.on('mousemove', 'selectionToolPointsLayer', onMouseMovePoint)
    map.on('mousedown', 'selectionToolPointsLayer', onMouseDownPoint)
    map.on('mouseleave', 'selectionToolPointsLayer', onMouseLeave)
    map.doubleClickZoom.disable()
  }

  // Required when map object is resinstated.
  static reAddToMap(map: mapboxgl.Map) {
    SelectionTool.addSource(map)
    SelectionTool.addToolLayers(map)
  }

  private static addToolLayers(map: mapboxgl.Map) {
    if (!map.getLayer('selectionToolLineLayer')) {
      map.addLayer({
        id: 'selectionToolLineLayer',
        type: 'line',
        source: 'selectionToolSource',
        layout: {
          'line-cap': 'round',
          'line-join': 'round',
        },
        paint: {
          'line-color': '#0B2948',
          'line-width': 2.5,
        },
        filter: ['in', '$type', 'LineString', 'Polygon'],
      })
    }

    if (!map.getLayer('selectionToolAreaLayer')) {
      map.addLayer({
        id: 'selectionToolAreaLayer',
        type: 'fill',
        source: 'selectionToolSource',
        paint: {
          'fill-color': '#0B2948',
          'fill-opacity': 0.1,
        },
        filter: ['in', '$type', 'Polygon'],
      })
    }
  }

  private static addSource(map: mapboxgl.Map) {
    // If there's already a selection delete it
    if (map.getSource('selectionToolSource')) {
      SelectionTool.deleteSelectionArea(map)
    }

    if (!map.getSource('selectionToolSource')) {
      map.addSource('selectionToolSource', {
        type: 'geojson',
        data: featureCollection,
      })
    }
  }

  static deactivateSelectionTool(map: mapboxgl.Map) {
    if (map.getLayer('selectionToolPointsLayer')) map.removeLayer('selectionToolPointsLayer')
    if (map.getLayer('selectionToolLastPointLayer')) map.removeLayer('selectionToolLastPointLayer')

    pointFeatures = []
    map.off('click', onMouseClick)
    map.off('mousemove', 'selectionToolAreaLayer', onMouseMoveArea)
    map.off('mousedown', 'selectionToolAreaLayer', onMouseDownArea)
    map.off('mouseleave', 'selectionToolAreaLayer', onMouseLeave)

    map.off('mousemove', 'selectionToolPointsLayer', onMouseMovePoint)
    map.off('mousedown', 'selectionToolPointsLayer', onMouseDownPoint)
    map.off('mouseleave', 'selectionToolPointsLayer', onMouseLeave)

    map.off('drag', onDragPoint)
    map.off('drag', onDragArea)
    map.off('dragend', onUp)

    map.getCanvas().style.cursor = 'default'
  }

  static deleteSelectionArea = (map: mapboxgl.Map) => {
    SelectionTool.deactivateSelectionTool(map)
    if (setTheSelectionArea) setTheSelectionArea({ area: null, perimeter: null })
    if (map.getLayer('selectionToolAreaLayer')) map.removeLayer('selectionToolAreaLayer')
    if (map.getLayer('selectionToolLineLayer')) map.removeLayer('selectionToolLineLayer')
    if (map.getSource('selectionToolSource')) {
      const source = map.getSource('selectionToolSource') as mapboxgl.GeoJSONSource
      source.setData({
        type: 'FeatureCollection',
        features: [],
      })
      map.removeSource('selectionToolSource')
    }
    setItHasSelection(false)
    setSelecting(false)
    updateTheDrawAreas({ drawAreas: [] })
    map.doubleClickZoom.enable()
  }
}

const popup = new mapboxgl.Popup({
  closeButton: false,
  maxWidth: 'none',
  anchor: 'top-right',
  offset: 10,
  className: 'measureTooltip',
})

let setSelecting: React.Dispatch<React.SetStateAction<boolean>>
let setItHasSelection: React.Dispatch<React.SetStateAction<boolean>>
let setTheSelectionArea: React.Dispatch<React.SetStateAction<SelectionArea>>

let updateTheDrawAreas: (props: UpdateDrawAreasProps) => void
let displayTheSnackbar: (snackbar: Snackbar) => void

const featureCollection: FeatureCollection = {
  type: 'FeatureCollection',
  features: [],
}

let pointFeatures: Feature[] = []
let dragging = false

const onMouseEnterPoint = (event: MapLayerMouseEvent) => {
  const map = event.target

  if (dragging) return

  const feature = event.features ? event.features[0] : null
  if (!feature) return

  const point = feature.geometry as Point
  const pointPosition = point.coordinates
  popup.setLngLat({ lng: pointPosition[0], lat: pointPosition[1] })

  if (feature.properties?.last) popup.setText('Click again to complete, drag to change')
  else popup.setText('Drag to change, click to remove')

  popup.addTo(map)
}

const onMouseMovePoint = (event: MapLayerMouseEvent) => {
  event.preventDefault()
  const map = event.target
  map.getCanvas().style.cursor = 'pointer'
}

const onMouseLeave = (event: MapLayerMouseEvent) => {
  event.preventDefault()
  const map = event.target
  map.getCanvas().style.cursor = 'crosshair'
  popup.remove()
}

const onMouseMoveArea = (event: MapLayerMouseEvent) => {
  const map = event.target

  // Give priority to a point!
  const anypoints = map.queryRenderedFeatures(event.point, {
    layers: ['selectionToolPointsLayer'],
  })

  if (anypoints.length >= 1) return

  event.preventDefault()

  map.getCanvas().style.cursor = 'grab'
}

const onMouseClick = (event: MapLayerMouseEvent) => {
  event.preventDefault()
  const map = event.target
  const clickedFeatures = map.queryRenderedFeatures(event.point, {
    layers: ['selectionToolPointsLayer'],
  })

  if (clickedFeatures.length) {
    const id = clickedFeatures[0].properties?.id
    const lastPointId = pointFeatures[pointFeatures.length - 1].properties?.id
    const clickedLastPointTwice = id == lastPointId
    if (clickedLastPointTwice && pointFeatures.length > 2) {
      const coords = pointFeatures.map(
        (pointFeature) => (pointFeature.geometry as Point).coordinates,
      )
      const polygonFeature: DrawArea = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [[...coords, coords[0]]],
        },
      }
      updateTheDrawAreas({ drawAreas: [polygonFeature] })
      setSelecting(false)
      setItHasSelection(true)

      // Enabling dbl cick zoom too quicly in click events
      // still detects the dbl click - grr
      setTimeout(() => {
        map.doubleClickZoom.enable()
      }, 200)

      return
    }
    pointFeatures = pointFeatures.filter((point) => point.properties?.id !== id)
    if (pointFeatures.length == 1) pointFeatures = []

    // Clear the last flag so no nodes are highlighted
    if (pointFeatures.length == 2)
      pointFeatures.forEach((p) => (p.properties = { ...p.properties, last: false }))
  } else {
    const pointFeatre: Feature = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [event.lngLat.lng, event.lngLat.lat],
      },
      properties: {
        id: String(new Date().getTime()),
      },
    }

    pointFeatures.push(pointFeatre)
  }

  updateFeatureCollection(map)
}

let draggedPoint: Point

const onDragPoint = (event: MapLayerMouseEvent) => {
  event.preventDefault()
  const map = event.target
  const coords = event.lngLat

  draggedPoint.coordinates = [coords.lng, coords.lat]
  updateFeatureCollection(map)
}

const onUp = (event: MapLayerMouseEvent) => {
  event.preventDefault()
  const map = event.target
  map.off('mousemove', onDragPoint)
  map.off('mousemove', onDragArea)
  dragging = false
}

const onMouseDownPoint = (event: MapLayerMouseEvent) => {
  event.preventDefault()
  const map = event.target

  const clickedFeatures = map.queryRenderedFeatures(event.point, {
    layers: ['selectionToolPointsLayer'],
  })
  const clickedId = clickedFeatures[0].properties?.id
  const clickedFeature = pointFeatures.find((feature) => feature.properties?.id == clickedId)

  if (!clickedFeature) return
  draggedPoint = clickedFeature.geometry as Point

  map.on('mousemove', onDragPoint)
  map.once('mouseup', onUp)
  dragging = true
}

let areaDragStartPosition: mapboxgl.LngLat

const onMouseDownArea = (event: MapLayerMouseEvent) => {
  const map = event.target

  // Give priority to a point!
  const anypoints = map.queryRenderedFeatures(event.point, {
    layers: ['selectionToolPointsLayer'],
  })

  if (anypoints.length >= 1) return

  event.preventDefault()

  map.getCanvas().style.cursor = 'grabbing'

  const draggedAreas = map.queryRenderedFeatures(event.point, {
    layers: ['selectionToolAreaLayer'],
  })

  if (draggedAreas.length) {
    areaDragStartPosition = event.lngLat
    map.on('mousemove', onDragArea)
    map.once('mouseup', onUp)
  }
}

const onDragArea = (event: MapLayerMouseEvent) => {
  event.preventDefault()
  const map = event.target
  const coords = event.lngLat
  const deltaLng = areaDragStartPosition.lng - coords.lng
  const deltaLat = areaDragStartPosition.lat - coords.lat
  areaDragStartPosition = event.lngLat

  pointFeatures = pointFeatures.map((pointFeature) => {
    const point = pointFeature.geometry as Point
    const [lng, lat] = point.coordinates
    point.coordinates = [lng - deltaLng, lat - deltaLat]
    return pointFeature
  })
  updateFeatureCollection(map)
}

function updateFeatureCollection(map: mapboxgl.Map) {
  const selectionArea: SelectionArea = { area: null, perimeter: null }

  if (pointFeatures.length <= 1) {
    featureCollection.features = pointFeatures
  } else if (pointFeatures.length == 2) {
    const coords: Position[] = pointFeatures.map(
      (pointFeature) => (pointFeature.geometry as Point).coordinates,
    )

    const lineStringFeature: Feature = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: coords,
      },
    }

    featureCollection.features = [...pointFeatures, lineStringFeature]
  } else if (pointFeatures.length > 2) {
    let coords: Position[] = pointFeatures.map(
      (pointFeature) => (pointFeature.geometry as Point).coordinates,
    )
    let polygonFeature: Feature = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [[...coords, coords[0]]],
      },
    }
    if (turf.kinks(polygonFeature.geometry as Polygon).features.length > 0) {
      displayTheSnackbar({ message: 'Self intersecting areas cannot be used.', type: 'info' })
      pointFeatures.pop()
      coords = pointFeatures.map((pointFeature) => (pointFeature.geometry as Point).coordinates)
      polygonFeature = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [[...coords, coords[0]]],
        },
      }
    }
    pointFeatures.forEach(
      (pointFeature) => (pointFeature.properties = { ...pointFeature.properties, last: false }),
    )
    const lastPoint = pointFeatures[pointFeatures.length - 1]
    if (lastPoint) lastPoint.properties = { ...lastPoint.properties, last: true }
    featureCollection.features = [...pointFeatures, polygonFeature]

    const polygon = turf.polygon([[...coords, coords[0]]])
    const area = turf.area(polygon)
    const perimeter = turf.length(polygon, { units: 'meters' })
    selectionArea.area = formatArea(area)
    selectionArea.perimeter = formatDistance(perimeter)
    setTheSelectionArea(selectionArea)
  }

  const source = map.getSource('selectionToolSource') as mapboxgl.GeoJSONSource
  source.setData(featureCollection)
}

interface SelectionToolOutputProps {
  selectionArea: SelectionArea
  isSelecting: boolean
  handleClose: () => void
}

export const SelectionToolOutput = ({
  selectionArea,
  isSelecting,
  handleClose,
}: SelectionToolOutputProps) => {
  const theme = useTheme()
  return (
    <Box css={mapTool(theme, 'selection')}>
      <Box css={mapToolHeader}>
        <Typography css={mapToolHeaderTitle}>Selection area</Typography>
        <Box css={mapToolHint(theme, 'selection')}>
          {isSelecting &&
            (selectionArea.area == null ? (
              <Typography variant="caption">
                Click on the map to trace an area you want to select
              </Typography>
            ) : (
              <Typography variant="caption">
                Click the last point twice to complete the area
              </Typography>
            ))}
        </Box>
        <Box css={mapToolClearButton}>
          <IconButton onClick={handleClose} size="small" color="secondary">
            <Clear />
          </IconButton>
        </Box>
      </Box>
      {selectionArea.area !== null && selectionArea.perimeter !== null && (
        <Box css={mapToolContent(theme)}>
          <div>
            <Typography>Area:</Typography>
            <Typography>Perimeter:</Typography>
          </div>
          <div>
            <Typography>{selectionArea.area}</Typography>
            <Typography>{selectionArea.perimeter}</Typography>
          </div>
        </Box>
      )}
    </Box>
  )
}
