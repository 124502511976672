/** @jsxImportSource @emotion/react */
import { Box, FormControlLabel, Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Fuse from 'fuse.js'
import { useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import { useDispatch } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useDebounce } from '../../../hooks/useDebounce'
import { setSideDrawerIsOpen } from '../../../redux/sideDrawer/sideDrawerSlice'
import {
  actionPLanTopMenu,
  actionPlanContainer,
  actionPlanContent,
  tableContainer,
} from './ActionPlan.styles'
import {
  filterDataByDivision,
  fuseSearchOptions,
  getAvailableDivisions,
  getStrategyData,
  hydrateData,
} from './ActionPlan.utils'
import { ActionPlanGroupTable } from './ActionPlanGroupTable'
import { ActionPlanLoading } from './ActionPlanLoading'
import { ActionPlanSearch } from './ActionPlanSearch'
import { DivisionFilters } from './DivisionFilters'
import { ActionPlanTable } from '@src/components/Organisms/ActionPlanTable'
import { Switch } from '@src/components/Atoms/Switch'

export interface ActionPlanWorkbookData {
  division: string
  model_alignment: string
  sector: string
  action: string
  goal: string
  initiative_id: number
  initiative_description: string
  year: number
  kpi: string
  target: string
  status: string
  capex: number | null
  priority: string
  lead: string
  more_info: string
}

export const ActionPlan = () => {
  const theme = useTheme()
  const dispatch = useDispatch()

  const [actionPlanData, setActionPlanData] = useState<ActionPlanWorkbookData[]>([])
  const [tableData, setTableData] = useState<ActionPlanWorkbookData[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [disableSort, setDisableSort] = useState(false)
  const [availableDivisions, setAvailableDivisions] = useState<Set<string>>()
  const [divisionFilters, setDivisionFilters] = useState(() => ['Horticulture'])
  const [groupTable, setGroupTable] = useState(false)

  const debouncedValue = useDebounce<string>(searchValue, 400)

  const { data, isLoading } = useSWR('/actionPlanData', getStrategyData)

  const fuse = useMemo(
    () => new Fuse<ActionPlanWorkbookData>(actionPlanData, fuseSearchOptions),
    [actionPlanData],
  )

  function handleUpdateDivisionFilters(filters: string[]) {
    if (filters.length) {
      setDivisionFilters(filters)
    }
  }

  function handleSearch(value: string) {
    setSearchValue(value)
  }

  useEffect(() => {
    const result = fuse.search(searchValue)

    const updatedData = result.map(({ item }) => item)

    if (!updatedData.length && !searchValue.length) {
      setTableData(actionPlanData)
      setDisableSort(false)
      return
    }
    setDisableSort(true)
    setTableData(updatedData)
    // We don't want to re-render if the searchValue changes as we are using 'debouncedValue'
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionPlanData, debouncedValue, fuse])

  useEffect(() => {
    if (!data) return

    setAvailableDivisions(getAvailableDivisions(data))

    const hydratedData = hydrateData(data)
    if (!divisionFilters.length) return

    const filteredData = filterDataByDivision({
      data: hydratedData,
      filters: divisionFilters,
    })

    setActionPlanData(filteredData)
    setTableData(filteredData)
  }, [data, divisionFilters])

  // TODO: remove this logic when data is ready for client
  const isReadyForScales = false
  if (!isReadyForScales) {
    return <Navigate to="/strategy/horizon-scan" />
  }

  if (isLoading || !availableDivisions) return <ActionPlanLoading />

  return (
    <Box css={actionPlanContainer({ theme })}>
      <Box css={actionPlanContent({ theme })}>
        <Box css={actionPLanTopMenu({ theme })}>
          <Stack direction="row" gap={6} justifyContent="center" alignItems="center">
            <DivisionFilters
              divisionFilters={divisionFilters}
              availableDivisions={availableDivisions}
              handleUpdateDivisionFilters={handleUpdateDivisionFilters}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={groupTable}
                  handleToggleChecked={() => setGroupTable((prev) => !prev)}
                />
              }
              label="Group by Action"
            />
          </Stack>
          <ActionPlanSearch searchValue={searchValue} handleSearch={handleSearch} />
        </Box>
        <Box css={tableContainer}>
          {groupTable ? (
            <ActionPlanGroupTable data={tableData} />
          ) : (
            <ActionPlanTable
              data={tableData}
              disableSort={disableSort}
              handleEnableSort={() => setDisableSort(false)}
              handleSetSideDrawerContent={() => null}
              handleSetSideDrawerIsOpen={(isOpen) => dispatch(setSideDrawerIsOpen(isOpen))}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
