import { PaletteColor } from '@mui/material/styles'

export type ThemeColours =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning'
  | 'red'
  | 'green'
  | 'grey'
  | 'blue'
  | 'orange'
  | 'yellow'
  | 'black'
  | 'white'
  | 'pink'

const primaryMain = '#0B2948'
const secondaryMain = '#FBFBFB'

export const primary: PaletteColor = {
  main: primaryMain,
  light: '#04497C',
  dark: '#061423',
  contrastText: secondaryMain,
  '100': '#89C9FB',
  '200': '#62B8F9',
  '300': '#3AA6F8',
  '400': '#1394F6',
  '500': '#087ED9',
  '600': '#0667B2',
}

export const blue: PaletteColor = {
  light: '#1394F6',
  main: '#087ED9',
  dark: '#0667B1',
  contrastText: '#FBFBFB',
  '100': '#75C0FA',
  '200': '#4EAFF9',
  '300': '#269DF7',
  '700': '#05508A',
  '800': '#044576',
  '900': '#032E4F',
}

export const secondary: PaletteColor = {
  main: secondaryMain,
  light: '#EBEBEB',
  dark: '#D6D6D6',
  contrastText: primaryMain,
  '400': '#C2C2C2',
  '500': '#ADADAD',
  '600': '#858585',
  '700': '#707070',
  '800': '#5C5C5C',
  '900': '#474747',
}

export const black: PaletteColor = {
  main: '#101010',
  light: '#3D3D3D',
  dark: '#0A0A0A',
  contrastText: secondaryMain,
  '100': '#A3A3A3',
  '200': '#8F8F8F',
  '300': '#7A7A7A',
  '400': '#666666',
  '500': '#525252',
  '700': '#292929',
}

export const white: PaletteColor = {
  main: '#FBFBFB',
  light: '#EBEBEB',
  dark: '#D6D6D6',
  contrastText: primaryMain,
  '400': '#C2C2C2',
  '500': '#ADADAD',
  '600': '#858585',
  '700': '#707070',
  '800': '#5C5C5C',
  '900': '#474747',
}

export const red: PaletteColor = {
  main: '#DF2935',
  light: '#E44E58',
  dark: '#C31D28',
  contrastText: secondaryMain,
  '100': '#F4B8BC',
  '200': '#EF959B',
  '300': '#EA7179',
  '700': '#A01821',
  '800': '#7C131A',
  '900': '#580E13',
}

export const pink: PaletteColor = {
  main: '#ff85a1',
  light: '#ff99ac',
  dark: '#ff5c8a',
  contrastText: secondaryMain,
  '100': '#fae0e4',
  '200': '#f7cad0',
  '300': '#fbb1bd',
  '700': '#ff477e',
  '800': '#ff0a54',
  '900': '#CC003D',
}

export const orange: PaletteColor = {
  main: '#EA7317',
  light: '#EE8E44',
  dark: '#CD6413',
  contrastText: white.main,
  '100': '#F8D2B4',
  '200': '#F5BB8F',
  '300': '#F1A46A',
  '700': '#A85210',
  '800': '#833F0C',
  '900': '#5D2D09',
}

export const grey: PaletteColor = {
  main: '#808080',
  light: '#999999',
  dark: '#707070',
  contrastText: secondaryMain,
  '100': '#D6D6D6',
  '200': '#C2C2C2',
  '300': '#ADADAD',
  '700': '#5C5C5C',
  '800': '#474747',
  '900': '#333333',
}

export const green: PaletteColor = {
  main: '#679436',
  light: '#7CB441',
  dark: '#53782B',
  contrastText: secondaryMain,
  '100': '#B9D996',
  '200': '#A5CE78',
  '300': '#91C45A',
  '700': '#3E5A20',
  '800': '#293C15',
  '900': '#151E0B',
}

export const yellow: PaletteColor = {
  light: '#FEED72',
  main: '#FDE84A',
  dark: '#FDE321',
  contrastText: black.main,
  '100': '#FFF9D6',
  '200': '#FFF4AD',
  '300': '#FFEF85',
  '700': '#FDE10D',
  '800': '#F2D602',
  '900': '#DEC402',
}

export const chartPalettes: { [key: string]: { [key: string]: string } } = {
  vulnerability: {
    low: yellow.main,
    moderate: orange.main,
    medium: orange.main,
    high: red.main,
    potential: pink.main,
    exposed: grey.main,
    unspecified: grey.light,
  },
}
