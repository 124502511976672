export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function formatNumberWithCommas(number: number) {
  return new Intl.NumberFormat('en-NZ').format(number)
}

export function formatCurrency(value: number) {
  return new Intl.NumberFormat('en-NZ', {
    style: 'currency',
    currency: 'NZD',
    notation: 'compact',
  }).format(value)
}

const scaleLookUp: { [key: string]: number } = { mm: 0.001, cm: 0.01, m: 1 }

export function convertToMetersOrCentimeters(value: number, unit: string | undefined): string {
  if (!unit) return value.toString()
  if (!scaleLookUp[unit]) return `${value} ${unit}`

  const scalor = scaleLookUp[unit]
  const scaledValue = value * scalor
  if (scaledValue < 1) return `${(scaledValue * 100).toFixed(0)}cm`

  return `${scaledValue.toFixed(1)}m`
}
