import { css } from '@emotion/react'

export interface StorybookContainerProps {
  width: string
  height: string
  resize?: boolean
  center?: boolean
}

export const storybookContainer = ({
  width,
  height,
  resize = true,
  center = false,
}: StorybookContainerProps) => css`
  width: ${width};
  height: ${height};

  border: 1px solid gray;
  border-radius: 4px;
  overflow: auto;

  display: ${center && 'flex'};
  align-items: ${center && 'center'};
  justify-content: ${center && 'center'};

  resize: ${resize ? 'both' : 'none'};

  gap: 8px;
  flex-wrap: wrap;

  padding: 40px;
`

export interface StorybookResizeWrapperProps extends StorybookContainerProps {
  children: JSX.Element[] | JSX.Element | React.ReactNode
  style?: React.CSSProperties
  className?: string
}

export const StorybookResizeWrapper = ({
  style,
  children,
  width,
  height,
  className,
  resize = true,
  center = false,
}: StorybookResizeWrapperProps) => {
  return (
    <div
      css={storybookContainer({ width, height, resize, center })}
      style={style}
      className={className}
    >
      {children}
    </div>
  )
}

export const checkboxMainClass = () => css`
  padding: 0px;
  margin: 0;
  margin-left: 12px;
`
