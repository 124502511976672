import { MapLayerStyles, useMap } from '@contexts/MapContext'
import { DrawArea, MapLayer } from '@redux/map/mapSlice'
import { Map } from 'mapbox-gl'
import {
  generateInformationLayerPalettes,
  getVisibleAssetIds,
  getVisibleInformationIds,
  VisibleAssets,
} from '@src/components/Molecules/MapView/RiskMapView.utilities'
import { createContext, useContext, useEffect, useRef, useState } from 'react'
import { RegionOption } from '@src/components/Molecules/RegionFilter'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { Snackbar } from '@contexts/SnackbarContext'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { renderLayersInOrder } from '@src/components/Molecules/MapView/Layers'
import { AddRegionMasksLayersProps } from '@contexts/MapContext/MapContext.types'
import { LegendLookup } from '@src/components/Molecules/Legend'
import { Polygon } from 'geojson'
import { AssetHazardInstance, VulnerabilityData } from '@src/components/Molecules/MapView'
import { useSnackbars } from '@contexts/SnackbarContext'

export interface LayerContextInterface {
  prepareAndUpdateLayers: ({ layers }: PrepareAndUpdateLayersProps) => Promise<void>
  visibleAssets: VisibleAssets
  vulnerabilityData: VulnerabilityData
  elementFilters: {
    [key: string]: { [key: string]: string | string[] }
  }
  filterKeyValues: { [key: string]: string[] } | null
  filterKeyValuesCache: { [key: string]: { [key: string]: string[] } } | null
  setElementFilters: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { [key: string]: string | string[] }
    }>
  >
  setfilterKeyValues: React.Dispatch<
    React.SetStateAction<{
      [key: string]: string[]
    } | null>
  >
  setFilterKeyValuesCache: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { [key: string]: string[] }
    } | null>
  >
}

export const LayerContext = createContext({} as LayerContextInterface)

export interface PrepareAndUpdateLayersProps {
  layers: MapLayer[]
  regionMasks?: RegionOption[] | null
}

export interface GetAndSetVisibleAssetsStateProps {
  map: Map
  assetLayers: MapLayer[]
  contextualLayers: MapLayer[]
  drawAreas: DrawArea[]
  regionMasks: RegionOption[] | null
  hasFilters: boolean
  elementFilters: { [key: string]: { [key: string]: string | string[] } }
}

export interface RenderLayersProps {
  map: Map
  layers: MapLayer[]
  mapLayerStyles: MapLayerStyles
  visibleAssets: VisibleAssets
  vulnerabilityData: VulnerabilityData
  legendsData: LegendLookup
  regionMasks: RegionOption[] | null
  addRegionMasksLayers: (props: AddRegionMasksLayersProps) => void
  hasFilters: boolean
}

export function LayerProvider({ children }: { children: React.ReactNode }) {
  const {
    map,
    regionMasks,
    setRegionMasks,
    cascadingAssetNDays,
    updateLayers,
    addRegionMasksLayers,
    mapLayerStyles,
    removeRegionMasksLayers,
  } = useMap()
  const {
    drawAreas,
    legendsData,
    layers: reduxLayers,
  } = useSelector((state: RootState) => state.map)
  const { displaySnackbar, removeSnackbar } = useSnackbars()

  /** FILTER BASED STATE */
  const [visibleAssets, setVisibleAssets] = useState<VisibleAssets>({})
  const [prevElementFilters, setPrevElementFilters] = useState<{
    [key: string]: { [key: string]: string | string[] }
  }>({})
  const [elementFilters, setElementFilters] = useState<{
    [key: string]: { [key: string]: string | string[] }
  }>({})
  const [filterKeyValues, setfilterKeyValues] = useState<{ [key: string]: string[] } | null>(null)
  const [filterKeyValuesCache, setFilterKeyValuesCache] = useState<{
    [key: string]: { [key: string]: string[] }
  } | null>(null)
  const [prevDrawAreas, setPrevDrawAreas] = useState<DrawArea[] | null>(() => drawAreas)
  const [prevRegionKeys, setPrevRegionKeys] = useState<string>('')
  /** FILTER BASED STATE END*/

  const [vulnerabilityData, setVulnerabilityData] = useState<VulnerabilityData>({})
  const cachedVulnData = useRef<{
    [key: string]: { [key: string]: AssetHazardInstance }
  }>({})
  const { tab } = useSelector((state: RootState) => state.sideDrawer)
  const isRiskStoryTab = tab === 'Stories'
  /**
   * Responsible for rendering the layers and in order, this is what physically draws the layers to the map and gets their data
   * We want to call prepareAndUpdateLayers sparingly and using the least reactivity possible, we don't want multiple calls to it and thus rerenders of the same data
   */
  function renderLayers({
    map,
    layers,
    mapLayerStyles,
    visibleAssets,
    vulnerabilityData,
    legendsData,
    addRegionMasksLayers,
    regionMasks,
    hasFilters,
  }: RenderLayersProps) {
    const informationLayerPalettes = generateInformationLayerPalettes(legendsData)
    renderLayersInOrder(
      map,
      layers,
      mapLayerStyles,
      visibleAssets,
      vulnerabilityData,
      legendsData,
      informationLayerPalettes,
      addRegionMasksLayers,
      regionMasks,
      hasFilters,
    )
  }

  /**
   * Gets and sets the visible ids for asset and contextual layers
   * @TODO look at caching this, but be very careful since it's a huge payload and could make things worse
   */
  const getAndSetVisibleAssetsState = async ({
    map,
    assetLayers,
    contextualLayers,
    drawAreas,
    regionMasks,
    hasFilters,
    elementFilters,
  }: GetAndSetVisibleAssetsStateProps): Promise<VisibleAssets> => {
    if (!hasFilters) {
      if (Object.keys(visibleAssets).length > 0) setVisibleAssets({})
      return {}
    }
    const [tempVisibleAssets, visibleInformationIds] = await Promise.all([
      getVisibleAssetIds(assetLayers, drawAreas, regionMasks, elementFilters),
      getVisibleInformationIds(contextualLayers, map, drawAreas),
    ])

    const combinedVisibleIds = { ...tempVisibleAssets, ...visibleInformationIds }
    setVisibleAssets(combinedVisibleIds)
    return combinedVisibleIds
  }

  /**
   * Gets and sets the vulnerability stats for the asset and hazard layers
   * has room to be optimized via only fetching the data that hasn't been fetched yet
   * @returns Returns the vulnerability data for the asset and hazard layers
   */
  const getAndSetVulnerabilityStats = async (
    assetLayers: MapLayer[],
    hazardLayers: MapLayer[],
  ): Promise<VulnerabilityData> => {
    const newVulnerabilityData: VulnerabilityData = {}

    const fetchPromises: Promise<unknown>[] = []

    const regions = regionMasks ? regionMasks.map((region) => region.region).join(',') : ''
    const regionQuery = new URLSearchParams({ regions }).toString()

    const drawAreaCoordinateArray: Polygon | null =
      drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
    const drawAreaCsv = drawAreaCoordinateArray
      ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
      : ''

    for (const assetLayer of assetLayers) {
      const elementFilterCacheKey = JSON.stringify(elementFilters[assetLayer.type])
      for (const hazardLayer of hazardLayers) {
        const nDays = cascadingAssetNDays[assetLayer.type] ?? 0
        const nDaysQuery = new URLSearchParams({ n_days: nDays.toString() }).toString()
        const nDaysQueryForAsset = assetLayer.is_cascading ? nDaysQuery : ''

        const cache_key = `${assetLayer.type}-${hazardLayer.assetTag}-${regions}-${drawAreaCsv}-${nDaysQueryForAsset}-${elementFilterCacheKey}`
        if (cachedVulnData.current[cache_key]) {
          if (!newVulnerabilityData[assetLayer.type]) newVulnerabilityData[assetLayer.type] = {}
          newVulnerabilityData[assetLayer.type][hazardLayer.assetTag] =
            cachedVulnData.current[cache_key]
          continue
        }

        fetchPromises.push(
          axios
            .get(
              `${BASE_API_URL}/api/asset/risk/${assetLayer.type}/${
                hazardLayer.assetTag
              }/${drawAreaCsv}?${regionQuery}${nDaysQueryForAsset ? `&${nDaysQueryForAsset}` : ''}`,
            )
            .then((response) => {
              if (!newVulnerabilityData[assetLayer.type]) newVulnerabilityData[assetLayer.type] = {}
              newVulnerabilityData[assetLayer.type][hazardLayer.assetTag] = response.data
              cachedVulnData.current[cache_key] = response.data
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error(error)
            }),
        )
      }
    }
    await Promise.all(fetchPromises)

    setVulnerabilityData((prev) => {
      return { ...prev, ...newVulnerabilityData }
    })
    return { ...vulnerabilityData, ...newVulnerabilityData }
  }

  /**
   * This function gets the region masks and sets them if they are different from the current region masks
   * @param newRegionMasks - Optional new region masks to be considered
   * @returns Returns the region masks to be used
   */
  const getRegionMasks = (newRegionMasks: RegionOption[] | null = null) => {
    if (newRegionMasks !== null && map) {
      if (newRegionMasks.length === 0) {
        if (regionMasks && regionMasks.length > 0) removeRegionMasksLayers({ map })
        return null
      } else {
        setRegionMasks(newRegionMasks)
        return newRegionMasks
      }
    }
    return regionMasks
  }

  /**
   * This function prepares all layers and considered, vuln, regions, polygons, visibility, and then executes the renderLayersInOrder function
   * This is the big controller of all things map layers
   * @param layers - All layers to be considered
   * @param newRegionMasks - Optional new region masks to be considered
   */
  const prepareAndUpdateLayers = async ({
    layers,
    regionMasks: newRegionMasks = null,
  }: PrepareAndUpdateLayersProps) => {
    if (!map) return

    const regionMasksToUse = getRegionMasks(newRegionMasks)
    const hazardLayers = layers.filter((layer) => layer?.layerType === 'hazard')
    const assetLayers = layers.filter((layer) => layer?.layerType === 'asset')
    const contextualLayers = layers.filter((layer) => layer?.layerType === 'information')

    const vulnStatsGet: Promise<VulnerabilityData> = getAndSetVulnerabilityStats(
      assetLayers,
      hazardLayers,
    )

    const nonEmptyElementFilters = Object.fromEntries(
      Object.entries(elementFilters).filter(([_, value]) => Object.keys(value).length > 0),
    )

    const hasFilters = !!(
      drawAreas.length > 0 ||
      (regionMasks && regionMasks.length) ||
      Object.keys(nonEmptyElementFilters).length > 0
    )

    const visAssetsGet = getAndSetVisibleAssetsState({
      map,
      assetLayers,
      contextualLayers,
      drawAreas,
      regionMasks: regionMasksToUse,
      hasFilters,
      elementFilters: nonEmptyElementFilters,
    })

    const loadingMessage: Snackbar = {
      message: 'Loading...',
      type: 'info',
    }

    const loadingTimeout = setTimeout(() => {
      displaySnackbar(loadingMessage)
    }, 1500)

    const res = await Promise.all([vulnStatsGet, visAssetsGet])
    let [vulnStats] = res
    const [, visAssets] = res
    vulnStats = vulnStats ?? vulnerabilityData

    clearTimeout(loadingTimeout)
    removeSnackbar(loadingMessage)

    // update the layers in the redux store
    updateLayers({ layers })

    // call function to draw layers on the map
    renderLayers({
      map,
      layers,
      mapLayerStyles,
      visibleAssets: visAssets,
      vulnerabilityData: vulnStats,
      legendsData,
      addRegionMasksLayers,
      regionMasks: regionMasksToUse,
      hasFilters,
    })
  }

  useEffect(() => {
    if (isRiskStoryTab) return
    // if the draw areas or region masks change, we need to re-render the layers
    // trying to avoid unnecessary renders though
    const elementFiltersString = JSON.stringify(elementFilters)
    const prevElementFiltersString = JSON.stringify(prevElementFilters)
    const regionKeys = regionMasks ? regionMasks.map((region) => region.region).join(',') : ''
    if (
      drawAreas !== prevDrawAreas ||
      regionKeys !== prevRegionKeys ||
      elementFiltersString !== prevElementFiltersString
    ) {
      prepareAndUpdateLayers({ layers: reduxLayers })
    }
    setPrevDrawAreas(drawAreas)
    setPrevElementFilters(elementFilters)
    setPrevRegionKeys(regionKeys)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawAreas, regionMasks, elementFilters])

  useEffect(() => {
    prepareAndUpdateLayers({ layers: reduxLayers })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cascadingAssetNDays])

  return (
    <LayerContext.Provider
      value={{
        prepareAndUpdateLayers,
        visibleAssets,
        vulnerabilityData,
        elementFilters,
        setElementFilters,
        filterKeyValues,
        setfilterKeyValues,
        filterKeyValuesCache,
        setFilterKeyValuesCache,
      }}
    >
      {children}
    </LayerContext.Provider>
  )
}

export const useLayers = () => useContext(LayerContext)
