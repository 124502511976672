import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const gaugeHalfContainer = css`
  width: 100%;
  height: 100%;
  padding: 10% 18%;
  padding-top: 15%;
`

export const svgContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;
  overflow: visible;

  & #arc-background {
    stroke: ${theme.palette.white.light};
  }
`

export const colourArc = (colour: string) => css`
  stroke: ${colour};
`
