import { createTheme } from '@mui/material/styles'

import { interImports } from './fonts'
import {
  black,
  blue,
  green,
  grey,
  orange,
  pink,
  primary,
  red,
  secondary,
  white,
  yellow,
} from './palette'

declare module '@mui/material/styles' {
  interface Color {
    50: string
    100: string
    200: string
    300: string
    400: string
    500: string
    600: string
    700: string
    800: string
    900: string
    A100: string
    A200: string
    A400: string
    A700: string
  }

  interface PaletteColor {
    light: string
    main: string
    dark: string
    contrastText: string
    50?: string
    100?: string
    200?: string
    300?: string
    400?: string
    500?: string
    600?: string
    700?: string
    800?: string
    900?: string
  }

  interface Palette {
    red: Palette['primary']
    pink: Palette['primary']
    orange: Palette['primary']
    green: Palette['primary']
    yellow: Palette['primary']
    black: Palette['primary']
    blue: Palette['primary']
    white: Palette['primary']
  }

  interface PaletteOptions {
    red: PaletteOptions['primary']
    pink: PaletteOptions['primary']
    orange: PaletteOptions['primary']
    green: PaletteOptions['primary']
    yellow: PaletteOptions['primary']
    black: PaletteOptions['primary']
    white: PaletteOptions['primary']
    blue: PaletteOptions['primary']
  }
}

/*
 * ADD COLOURS TO 'color' PROPS FOR EACH COMPONENT
 * This is a pain, but apparently is how we can make typescript
 * happy when we apply the color using the 'color' prop on MUI components
 * Would be nice to apply to all components in a single interface
 * but the official MUI doc recommends the following :(
 */
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    red: true
    pink: true
    orange: true
    green: true
    yellow: true
    black: true
    grey: true
    white: true
    blue: true
  }
}

declare module '@mui/material/Switch' {
  interface SwitchPropsColorOverrides {
    red: true
    pink: true
    orange: true
    green: true
    yellow: true
    black: true
    grey: true
    white: true
    blue: true
  }
}

declare module '@mui/material/Typography' {
  interface ResponsiveStyleValue {
    red: true
    pink: true
    orange: true
    green: true
    yellow: true
    black: true
    grey: true
    white: true
    blue: true
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    htmlFontSize: 16,
    fontSize: 14,
    subtitle1: {
      fontSize: '1.25rem', // 20px
    },
    subtitle2: {
      fontSize: '1rem', // 16px
    },
    body1: {
      fontSize: '0.875rem', // 14px
    },
    body2: {
      fontSize: '0.75rem', // 12px
    },
    button: {
      fontSize: '1rem', // 16px
      fontWeight: 500,
      letterSpacing: '0.05rem',
      textTransform: 'none',
    },
    caption: {
      fontSize: '0.75rem', // 12px
      fontWeight: 400,
      lineHeight: '0.75rem',
      letterSpacing: '0.03em',
    },
  },
  spacing: 4,
  shape: {
    borderRadius: 4,
  },
  palette: {
    mode: 'light',
    primary,
    secondary,
    error: red,
    warning: yellow,
    info: primary,
    success: green,
    divider: black?.light,
    black,
    red,
    pink,
    orange,
    green,
    yellow,
    grey,
    white,
    blue,
    background: {
      default: secondary.main,
      paper: secondary.main,
    },
    text: {
      primary: black.main,
      secondary: black[500],
      disabled: grey[300],
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `${interImports}`,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '28px',
          margin: '4px',
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        outlined: {
          padding: '11px',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: '4px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
          lineHeight: '0.75rem',
          letterSpacing: '0.03em',
          margin: '4px 2px',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: '0px 12px !important ',
          height: '24px',
        },
        tag: {
          margin: '0px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '11px',
        },
        root: {
          gap: '4px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '0px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          top: '-4px',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: false,
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
})

// Just a secondary theme mock example - this should be declared on app repo
export const themeScales = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      ...theme.palette.primary,
      main: '#23BB98',
      dark: '#1C6B58',
      light: '#E6F7F4',
    },
    secondary: {
      ...theme.palette.secondary,
      main: '#F2F2F2',
      dark: '#E6E6E6',
      light: '#FFFFFF',
    },
  },
})
