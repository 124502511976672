/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { AlertColor } from '@mui/material'
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { Snackbar } from '../../components/Molecules/Snackbar'

export interface SnackbarContext {
  displaySnackbar: (snackbar: Snackbar) => void
  removeSnackbar: (snackbar: Snackbar) => void
}

export const SnackbarContext = createContext({} as SnackbarContext)

export interface SnackbarProviderProps {
  children: React.ReactNode
}

export interface Snackbar {
  type: AlertColor
  message: string
}

const AUTO_DISMISS = 5000
const MAX_STACKED_ALERTS = 3

export function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [snackbars, setSnackbars] = useState<Snackbar[]>([])

  const activeAlertIds = snackbars.join(',')

  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(
        () => setSnackbars((alerts) => alerts.slice(0, alerts.length - 1)),
        AUTO_DISMISS,
      )
      return () => clearTimeout(timer)
    }
  }, [activeAlertIds])

  const displaySnackbar = useCallback(
    (alert: Snackbar) => {
      // dedupe messages
      if (snackbars.some((a) => a.message === alert.message)) return
      if (snackbars.length >= MAX_STACKED_ALERTS) {
        return setSnackbars((alerts) => [alert, ...alerts].slice(0, MAX_STACKED_ALERTS))
      }
      setSnackbars((alerts) => [alert, ...alerts])
    },
    [snackbars],
  )

  // dedupe messages because snackbars state isn't reliable to dedupe during set
  const uniqueSnackBars = snackbars.filter(
    (v, i, a) => a.findIndex((t) => t.message === v.message) === i,
  )

  const removeSnackbar = useCallback((alert: Snackbar) => {
    setSnackbars((alerts) => [...alerts.filter((a) => a !== alert)])
  }, [])

  const contextData: SnackbarContext = useMemo(
    () => ({ displaySnackbar, removeSnackbar }),
    [displaySnackbar, removeSnackbar],
  )

  return (
    <SnackbarContext.Provider value={contextData}>
      {children}
      {uniqueSnackBars.map((alert, index) => (
        <Snackbar
          key={alert.message + index}
          css={css`
            // Overwrite MUI top position to consider stacked alerts
            top: ${index * 60 + 12}px !important;
          `}
          message={alert.message}
          messageType={alert.type}
          removeSnackbar={() => removeSnackbar(alert)}
        />
      ))}
    </SnackbarContext.Provider>
  )
}

export const useSnackbars = () => useContext(SnackbarContext)
