import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const moreInformationModalContainer = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background-color: ${theme.palette.white.main};

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  width: 640px;
  min-height: 280px;

  border-radius: ${theme.spacing(1)};

  padding: ${theme.spacing(3)};
  padding-left: ${theme.spacing(4)};

  gap: ${theme.spacing(2)};

  box-shadow: ${theme.shadows[10]};

  @media (max-width: 650px) {
    width: 90vw;
    max-width: auto;
    min-width: auto;
    min-height: auto;
  }
`

export const moreInformationModalHeader = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  gap: ${theme.spacing(2)};
`

export const moreInformationModalTitle = ({ theme }: { theme: Theme }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${theme.spacing(2)};
`

export const moreInformationModalContent = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(4)};
  padding-top: 0;

  max-height: 80vh;
  overflow: auto;
`

export const moreInformationModalNoData = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacing(4)};
  padding-top: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1 1 auto;
`
