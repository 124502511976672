/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { sectionContainer } from './Section.styles'

export interface SectionProps {
  children: JSX.Element | JSX.Element[] | React.ReactNode
  paddingSize?: 'none' | 'small' | 'medium' | 'large' | 'extraLarge'
  center?: boolean
  className?: string
}

export const Section = ({
  children,
  className,
  paddingSize = 'medium',
  center = false,
}: SectionProps) => {
  const theme = useTheme()
  const padding = { none: 0, small: 1, medium: 2, large: 3, extraLarge: 4 }[paddingSize]

  return (
    <Box css={sectionContainer({ theme, padding, center })} className={className}>
      {children}
    </Box>
  )
}
