import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined'
import booleanIntersects from '@turf/boolean-intersects'
import mapboxgl, { Map, MapboxGeoJSONFeature } from 'mapbox-gl'

import { DrawArea, MapLayer } from '@redux/map/mapSlice'
import { BASE_API_URL, BASE_MAP_LINZ } from '../../../app-constants'

import { createRoot } from 'react-dom/client'
import { fetchAssetSentence, fetchHazardTooltipDetails } from '../../../api/map/layers'
import { MapMarker } from '../../Atoms/MapMarker'
import { VectorTileset } from '../LayersControl'
import { LegendLookup, LegendProps } from '../Legend'
import axios from '@src/utils/customAxios'
import { GeoJsonProperties, Polygon } from 'geojson'
import { RegionOption } from '../RegionFilter'
import { firstLetterToUpperCase, snakeCaseToTitleCase } from '@src/utils/strings.utils'
import { AssetHazardInstances, VulnerabilityData } from './RiskMapView'
import { Tooltip } from '@src/components/Atoms/Tooltip/Tooltip'
import { MapTooltipProps } from '../MapTooltip/MapTooltip'

export const LINZ_SOURCE_ID = 'linz-basemap'
export const LINZ_LAYER_ID = 'linz-basemap-layer'

export type VisibleAssets = { [assetType: string]: { [asset_id: number]: boolean } }
export type AffectedAssets = { [layerType: string]: Set<number> }

export type BaseInformationLayerPalette = {
  type: 'categorical' | 'continuous' | 'keyValue'
  dataColumn: string
}

export type CategoricalInformationLayerPalette = BaseInformationLayerPalette & {
  type: 'categorical'
  palette: string[]
}

export type ContinuousInformationLayerPalette = BaseInformationLayerPalette & {
  type: 'continuous'
  palette: { color: string; value: number }[]
}

export type KeyValueInformationLayerPalette = BaseInformationLayerPalette & {
  type: 'keyValue'
  palette: { [key: string]: string }
}

export type InformationLayerPalette =
  | CategoricalInformationLayerPalette
  | ContinuousInformationLayerPalette
  | KeyValueInformationLayerPalette

export type InformationLayerPalettes = {
  [paletteId: string]: InformationLayerPalette
}

export function getUniqueFeatures(features: MapboxGeoJSONFeature[], comparatorProperty: string) {
  const uniqueIds = new Set()
  const uniqueFeatures = []
  for (const feature of features) {
    if (!feature.properties) continue

    const id = feature.properties[comparatorProperty]
    if (!uniqueIds.has(id)) {
      uniqueIds.add(id)
      uniqueFeatures.push(feature)
    }
  }
  return uniqueFeatures
}

export function getLayersByType(layers: MapLayer[], layerType: 'hazard' | 'asset' | 'information') {
  return layers.filter((layer) => layer.layerType === layerType)
}

export function byAssetIdIn(assetIdLookup: { [assetId: number]: unknown }): mapboxgl.Expression {
  if (!assetIdLookup) return ['boolean', false]

  return ['has', ['to-string', ['get', 'asset_id']], ['literal', assetIdLookup]]
}

export async function getAssetsAffectedByHazards(
  layers: MapLayer[],
  vulnerabilityData: VulnerabilityData,
): Promise<{ [layerType: string]: Set<number> }> {
  return new Promise((resolve, reject) => {
    try {
      const assetsAffectedByHazards: { [layerType: string]: Set<number> } = {}

      const assetLayers = layers.filter((layer) => layer.layerType === 'asset')
      assetLayers.map((assetLayer) => {
        if (!assetsAffectedByHazards[assetLayer.type])
          assetsAffectedByHazards[assetLayer.type] = new Set<number>()

        const vulnerableAssets = assetsAffectedByHazards[assetLayer.type]
        for (const assetTag in vulnerabilityData[assetLayer.type]) {
          const assetVulnerabilities = vulnerabilityData[assetLayer.type][assetTag]
          for (const assetId in assetVulnerabilities) {
            vulnerableAssets.add(parseInt(assetId))
          }
        }
      })

      resolve(assetsAffectedByHazards)
    } catch (error) {
      reject({})
    }
  })
}

export function getHazardColourOfAsset(
  hazardLayers: MapLayer[],
  assetHazardInstances: AssetHazardInstances,
  vulnerabilityPalette: string[],
): mapboxgl.Expression {
  if (!hazardLayers.length) return ['to-color', '#0b2a48']

  const maxVulnerabilityExpr = maxVulnerabilityExpression(hazardLayers, assetHazardInstances)

  return ['to-color', ['at', maxVulnerabilityExpr, ['literal', vulnerabilityPalette]]]
}

export function maxVulnerabilityExpression(
  hazardLayers: MapLayer[],
  assetHazardInstances: AssetHazardInstances,
): mapboxgl.Expression | number {
  if (!assetHazardInstances) return 0

  const expressions = hazardLayers.map((hazard) => {
    const assetIdsVuln = assetHazardInstances[hazard.assetTag]
    if (!assetIdsVuln) return 0

    return [
      'coalesce',
      [
        'get',
        'vulnerability_style',
        [
          'coalesce',
          ['get', ['to-string', ['get', 'asset_id']], ['literal', assetIdsVuln]],
          ['literal', { vulnerability_style: 0 }],
        ],
      ],
      0,
    ]
  }) as mapboxgl.Expression[] | number[]

  if (expressions.length === 1) return expressions[0]
  return ['max', ...expressions]
}

/*
  getHazardAssetIcon returns the iconId appended with the vulnerbility value.
  E.g.: town_hall-3
  When the town_hall icon is loaded one for each vulnerability value is generated which has
  the pin color replaced with the associated color from the vulnerability palette.
  All this because MapBox can't really handle multi element symbols
*/
export function getHazardAssetIcon(
  iconId: string,
  hazardLayers: MapLayer[],
  assetHazardInstances: AssetHazardInstances,
): mapboxgl.Expression {
  const assetIcon = iconId.replace('s3://', '').replace('.svg', '')
  if (!hazardLayers || hazardLayers.length == 0) return ['literal', `${assetIcon}-default`]

  /* 
    To reduce the complexity of the mapbox expression first we simplify the assetHazardInstances object from:
    {
      "fluvial_flooding_0SLR_500ARI_NoStopbank_NoEQ": {
        "40": {
          "exposure_value": "12",
          "vulnerability": "low",
          "vulnerability_style": 3,
          "metric": 1
        }
        ...
      }
    }

    to:
    {
      "fluvial_flooding_0SLR_500ARI_NoStopbank_NoEQ": {
        "40": 3
        ...
      }
    }
  */

  const simplifiedAssetHazardInstances: {
    [hazardAssetTag: string]: { [assetId: string]: number }
  } = {}

  const hazardAssetTags = Object.keys(assetHazardInstances)

  hazardAssetTags.forEach((hazardAssetTag) => {
    const assetIds = Object.keys(assetHazardInstances[hazardAssetTag])

    if (!simplifiedAssetHazardInstances[hazardAssetTag])
      simplifiedAssetHazardInstances[hazardAssetTag] = {}

    assetIds.forEach((assetId) => {
      simplifiedAssetHazardInstances[hazardAssetTag][assetId] =
        assetHazardInstances[hazardAssetTag][assetId].vulnerability_style
    })
  })

  return [
    'concat',
    assetIcon,
    '-',
    [
      'max',
      ...hazardLayers.map((hazard) => {
        if (!assetHazardInstances || !simplifiedAssetHazardInstances[hazard.assetTag]) return 0

        return [
          'coalesce', // the 'get' can return null if so 'coalesce' returns 0
          [
            'get',
            ['to-string', ['get', 'asset_id']],
            ['literal', simplifiedAssetHazardInstances[hazard.assetTag]],
          ],
          0,
        ]
      }),
    ],
  ]
}

export async function getVisibleInformationIds(
  informationLayers: MapLayer[],
  map: Map,
  drawAreas: DrawArea[],
): Promise<VisibleAssets> {
  const infoIdsToDisplay: VisibleAssets = {}

  for (const infoLayer of informationLayers) {
    const vectorTileset = infoLayer.tilesets[0] as VectorTileset
    infoIdsToDisplay[vectorTileset.id] = {}

    const infoIdsInView = map.querySourceFeatures(vectorTileset.id, {
      sourceLayer: vectorTileset.sourceLayer,
    })

    // Add all the information element ids as there are no selection areas to filter
    if (!drawAreas.length) {
      for (const infoElement of infoIdsInView) {
        if (infoElement.id === null || infoElement.id === undefined) continue
        const id = parseInt(`${infoElement.id}`)
        infoIdsToDisplay[vectorTileset.id][id] = true
      }
    } else {
      for (const infoElement of infoIdsInView) {
        if (infoElement.id === null || infoElement.id === undefined) continue
        const id = parseInt(`${infoElement.id}`)

        let featureIsSelected = true

        if (
          drawAreas.length > 0 &&
          infoElement.geometry.type != 'MultiPolygon' &&
          infoElement.geometry.type != 'MultiLineString'
        )
          // TODO: make this work with MultiPolygons and MultiLineString!
          featureIsSelected = drawAreas
            .map((selectionArea) => booleanIntersects(infoElement, selectionArea))
            .some(Boolean)

        if (
          infoElement.geometry.type == 'MultiPolygon' ||
          infoElement.geometry.type == 'MultiLineString'
        )
          featureIsSelected = false

        if (featureIsSelected) infoIdsToDisplay[vectorTileset.id][id] = true
      }
    }
  }
  return infoIdsToDisplay
}

export async function getVisibleAssetIds(
  assetLayers: MapLayer[],
  drawAreas: DrawArea[],
  regionMasks: RegionOption[] | null,
  elementFilters: { [key: string]: { [key: string]: string | string[] } } | null,
): Promise<VisibleAssets> {
  try {
    if (!assetLayers.length) return {}
    const assetIdsToDisplay: VisibleAssets = {}

    const assetLayerTypesCsv = assetLayers.map((assetLayer) => assetLayer.type).join(',')
    const drawAreaCoordinateArray: Polygon | null =
      drawAreas.length > 0 ? (drawAreas[0].geometry as Polygon) : null
    const drawAreaCsv = drawAreaCoordinateArray
      ? drawAreaCoordinateArray.coordinates[0].flat().join(',')
      : ''

    const regions = regionMasks ? regionMasks.map((region) => region.region).join(',') : ''
    const regionQuery = new URLSearchParams({ regions }).toString()

    const assetIdResponse = await axios.post<{ [asset_type: string]: number[] }>(
      `${BASE_API_URL}/api/asset/${assetLayerTypesCsv}/${drawAreaCsv}?${regionQuery}`,
      {
        identifying_information: elementFilters,
      },
    )

    const assetIdsByLayerType = assetIdResponse.data

    for (const assetLayer of assetLayers) {
      assetIdsToDisplay[assetLayer.type] = {}
    }

    // Convert assetId arrays into hash tables
    for (const layerType in assetIdsByLayerType) {
      const assetIds = assetIdsByLayerType[layerType]
      const assetIdHashTable: { [assetId: number]: boolean } = {}

      assetIds.forEach((assetId) => {
        assetIdHashTable[assetId] = true
      })
      assetIdsToDisplay[layerType] = assetIdHashTable
    }
    return assetIdsToDisplay
  } catch (error) {
    return {}
  }
}

export function addBaseMapStyle(map: Map, mapStyle: string) {
  if (mapStyle === BASE_MAP_LINZ) {
    // Add LINZ basemap layer
    addLINZMap(map)
  }

  // Add 3D terrain to map
  map.addSource('mapbox-dem', {
    type: 'raster-dem',
    url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
    tileSize: 512,
    maxzoom: 14,
  })

  // Set elevation source for Mapbox GL
  map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 })
}

export function addLINZMap(map: Map) {
  map.addSource(LINZ_SOURCE_ID, {
    attribution:
      '© <a href="//www.linz.govt.nz/linz-copyright">LINZ CC BY 4.0</a> © <a href="//www.linz.govt.nz/data/linz-data/linz-basemaps/data-attribution">Imagery Basemap contributors</a>',
    type: 'raster',
    tiles: [
      'https://basemaps.linz.govt.nz/v1/tiles/aerial/3857/{z}/{x}/{y}.webp?api=d01h3zggjmdh8tgmafm8nq0j6w8',
    ],
    tileSize: 256,
  })
  map.addLayer({
    id: LINZ_LAYER_ID,
    type: 'raster',
    source: 'linz-basemap',
    'source-layer': 'linz-basemap',
    layout: { visibility: 'visible' },
  })
}

export function generateVulnerabilityPalette(legends: LegendLookup, type: string): string[] {
  if (!legends || !legends['vulnerability']) return []
  const vulnerabilityLegend = type
    ? legends[type] ?? legends['vulnerability']
    : legends['vulnerability']
  const theOnlySectionForTheMoment = vulnerabilityLegend.sections[0]
  return theOnlySectionForTheMoment.items?.map((item) => (item.color ? item.color : '')) ?? []
}

export function generateInformationLayerPalettes(legends: LegendLookup): InformationLayerPalettes {
  if (!legends) return {}
  const informationLayerPalettes: InformationLayerPalettes = {}

  for (const legendId in legends) {
    const legend = legends[legendId] as LegendProps
    const theOnlySectionForTheMoment = legend.sections[0]

    switch (theOnlySectionForTheMoment.type) {
      case 'categorical':
        {
          if (!theOnlySectionForTheMoment.items) continue

          const palette: string[] = []
          theOnlySectionForTheMoment.items.forEach((item) => {
            if (item.color) palette.push(item.color)
          })

          informationLayerPalettes[legendId] = {
            type: 'categorical',
            palette,
            dataColumn: theOnlySectionForTheMoment.dataColumn ?? '',
          }
        }
        break
      case 'continuous':
        {
          if (!theOnlySectionForTheMoment.stops) continue
          const palette: { color: string; value: number }[] = []
          theOnlySectionForTheMoment.stops.forEach((stop) => {
            if (stop.color) palette.push({ color: stop.color, value: stop.value })
          })

          informationLayerPalettes[legendId] = {
            type: 'continuous',
            palette,
            dataColumn: theOnlySectionForTheMoment.dataColumn ?? '',
          }
        }
        break
      case 'keyValue':
        {
          if (!theOnlySectionForTheMoment.keyValues) continue
          const palette: { [key: string]: string } = {}
          Object.keys(theOnlySectionForTheMoment.keyValues).forEach((key) => {
            if (theOnlySectionForTheMoment.keyValues)
              palette[key] = theOnlySectionForTheMoment.keyValues[key].color
          })

          informationLayerPalettes[legendId] = {
            type: 'keyValue',
            palette,
            dataColumn: theOnlySectionForTheMoment.dataColumn ?? '',
          }
        }
        break
    }
  }

  return informationLayerPalettes
}

export async function getHazardScenarioDetails(
  assetType: string,
  assetId: number,
  hazardLayers: MapLayer[],
) {
  const hazardScenarios = hazardLayers.map((hazardLayer) => {
    return {
      assetTag: hazardLayer.assetTag,
      title: hazardLayer.display_name,
      hazard_id: hazardLayer.hazard_id,
    }
  })

  const hazardDetailsResponse = await Promise.all(
    hazardScenarios.map(async (hazardScenario) => {
      try {
        const result = await fetchHazardTooltipDetails(assetType, hazardScenario.assetTag, assetId)
        return {
          hazard_id: hazardScenario.hazard_id,
          title: hazardScenario.title,
          assetTag: hazardScenario.assetTag,
          data: result,
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `Error fetching details for hazard scenario ${hazardScenario.assetTag}:`,
          error,
        )
        return null
      }
    }),
  )

  return hazardDetailsResponse.filter((response) => response !== null)
}

export function getInformationDetailsForSelectedFeature(
  feature: MapboxGeoJSONFeature,
  informationLayers: MapLayer[],
): MapTooltipProps['information'] {
  if (
    !feature?.properties ||
    feature.id === null ||
    feature.id === undefined ||
    !feature?.layer ||
    !feature.layer.source
  )
    return

  const selectedInformation = informationLayers.find(
    (informationLayer) => informationLayer.tilesets[0].id === feature.layer.source,
  )

  if (!selectedInformation) return
  const id = parseInt(`${feature.id}`)

  let value = feature.properties.value
  if (
    (feature.properties.value === null || feature.properties.value === undefined) &&
    feature.properties?.hover_comment
  ) {
    value = feature.properties?.hover_comment
  }

  const information = {
    id,
    value,
    title: selectedInformation.display_name,
    icon: selectedInformation.icon,
  }

  return information
}

export function getHazardInfoForSelectedFeature(
  feature: MapboxGeoJSONFeature,
  hazardLayers: MapLayer[],
): MapTooltipProps['information'] {
  if (
    !feature?.properties ||
    feature.id === null ||
    feature.id === undefined ||
    !feature?.layer ||
    !feature.layer.source
  )
    return

  const selectedInformation = hazardLayers.find((hazardLayer) => {
    return (
      hazardLayer.tilesets[0].id === feature.layer.source ||
      (hazardLayer?.infoLookupTileset?.length &&
        hazardLayer.infoLookupTileset.some((infoLookup) => {
          return infoLookup.id === feature.layer.source
        }))
    )
  })

  if (!selectedInformation) return
  const id = parseInt(`${feature.id}`)

  let value = feature.properties.display_value
  // properties can sometimes include one or several values keyed by the hazard layer id
  for (const prop of Object.keys(feature.properties)) {
    if (hazardLayers.some((hazardLayer) => hazardLayer.tilesets[0].id.includes(prop))) {
      value = feature.properties[prop]
      break
    }
  }
  if (
    !value &&
    (feature.properties.display_value === null || feature.properties.display_value === undefined) &&
    feature.properties?.hover_comment
  ) {
    value = feature.properties.value
  }

  const information = {
    id,
    value,
    title: selectedInformation.display_name,
    icon: selectedInformation.icon,
  }

  return information
}

export async function getAssetAndHazardDetailsForSelectedFeature(
  feature: MapboxGeoJSONFeature,
  assetLayers: MapLayer[],
  hazardLayers: MapLayer[],
  legendsData: LegendLookup,
) {
  if (
    !feature?.properties ||
    feature.properties.asset_id == undefined ||
    !feature?.layer ||
    feature.layer.source == undefined
  )
    return null

  const selectedAsset = assetLayers.find(
    (assetLayer) => assetLayer.tilesets[0].id === feature.layer.source,
  )

  if (!selectedAsset) return null

  const assetId = feature.properties.asset_id
  const assetType = selectedAsset.type
  const assetSentence = await fetchAssetSentence(assetType, assetId)

  if (!hazardLayers.length)
    return {
      asset: {
        id: assetId,
        icon: selectedAsset.icon,
        name: selectedAsset.display_name,
        type: assetType,
        sentence: assetSentence,
      } as MapTooltipProps['asset'],
      hazards: {} as MapTooltipProps['hazards'],
    }

  const hazardDetailsResponse = await getHazardScenarioDetails(assetType, assetId, hazardLayers)

  const highestExposure = hazardDetailsResponse.reduce((acc, curr) => {
    if (!curr?.data) return acc
    const exposure_value = +curr.data.exposure_value

    if (exposure_value > acc) return exposure_value
    return acc
  }, 0)

  const highestVulnerability = hazardDetailsResponse.reduce(
    (acc, curr) => {
      if (!curr?.data) return acc
      const vulnerability_style = +curr.data.vulnerability_style
      const vulnerability = curr.data.vulnerability
      if (vulnerability_style > acc.vulnerability_style) {
        return {
          vulnerability_style,
          vulnerability,
        }
      }
      return acc
    },
    {
      vulnerability_style: 0,
      vulnerability: '',
    },
  )

  const vulnerabilityLegend = legendsData['vulnerability'].sections[0].items
  if (!vulnerabilityLegend) return null

  const highestVulnerabilityValue = highestVulnerability.vulnerability_style

  const asset: MapTooltipProps['asset'] = {
    id: assetId,
    icon: selectedAsset.icon,
    name: selectedAsset.display_name,
    type: assetType,
    sentence: assetSentence,

    vulnerabilityLabel: firstLetterToUpperCase(highestVulnerability.vulnerability),
    vulnerabilityColour: vulnerabilityLegend[highestVulnerabilityValue].color,

    exposure: highestExposure.toString(),
    exposureUnit: selectedAsset.unit,
  }

  const hazardsEffectingAsset = hazardDetailsResponse.filter(
    (hazard) => hazard && hazard?.data !== null,
  )

  const hazards = hazardsEffectingAsset.map((hazard) => {
    if (!hazard?.data) return

    const currentHazardLayer = hazardLayers.find(
      (hazardLayer) => hazardLayer.assetTag === hazard.assetTag,
    )

    const hazardMetric = currentHazardLayer?.tilesets[0].unit
    return {
      hazard_id: hazard.hazard_id,
      title: snakeCaseToTitleCase(hazard.title),
      sentence: hazard.data.sentence,
      vulnerabilityColour: vulnerabilityLegend[hazard.data.vulnerability_style].color,
      exposure_value: hazard.data.exposure_value,
      metric: hazard.data.metric,
      unit: hazardMetric ? hazardMetric : '',
      vulnerabilityLabel: firstLetterToUpperCase(hazard.data.vulnerability),
    }
  }) as MapTooltipProps['hazards']

  return {
    asset,
    hazards,
  } as {
    asset: MapTooltipProps['asset']
    hazards: MapTooltipProps['hazards']
  }
}

export function addSelectedMarkerToMap({
  map,
  coordinates,
  colour,
}: {
  map: mapboxgl.Map
  coordinates: mapboxgl.LngLatLike
  colour: string
}) {
  removeSelectedMarkerFromMapIfExists()

  const selectedMarkerRef = document.createElement('div')
  selectedMarkerRef.id = 'selected-marker'

  createRoot(selectedMarkerRef).render(
    <MapMarker colour={colour}>
      <Tooltip title="Explore additional details within the information panel">
        <TaskOutlinedIcon />
      </Tooltip>
    </MapMarker>,
  )

  new mapboxgl.Marker(selectedMarkerRef).setLngLat(coordinates).addTo(map)
}

export function removeSelectedMarkerFromMapIfExists() {
  const selectedMarker = document.getElementById('selected-marker')
  if (selectedMarker) selectedMarker.remove()
}

type Coordinate = [number, number]
type Coordinates = Coordinate | Coordinate[] | Coordinate[][]
type BoundingCoordinates = { max_coord: Coordinate; min_coord: Coordinate }

export function getCenterOfFeature(feature: MapboxGeoJSONFeature): [number, number] | null {
  if (!feature?.geometry) return null
  const coordinates = (feature.geometry as GeoJsonProperties)?.coordinates as Coordinates
  if (!coordinates) return null

  const getBoundsOfGeometry = (coords: Coordinates): BoundingCoordinates => {
    if (Array.isArray(coords[0])) {
      return coords.reduce(
        (acc: BoundingCoordinates, curr) => {
          const { max_coord, min_coord } = getBoundsOfGeometry(curr as Coordinates)
          return {
            max_coord: [
              Math.max(acc.max_coord[0], max_coord[0]),
              Math.max(acc.max_coord[1], max_coord[1]),
            ],
            min_coord: [
              Math.min(acc.min_coord[0], min_coord[0]),
              Math.min(acc.min_coord[1], min_coord[1]),
            ],
          }
        },
        { max_coord: [-Infinity, -Infinity], min_coord: [Infinity, Infinity] },
      )
    }
    return { max_coord: coords as Coordinate, min_coord: coords as Coordinate }
  }

  const { max_coord, min_coord } = getBoundsOfGeometry(coordinates)
  return [(max_coord[0] + min_coord[0]) / 2, (max_coord[1] + min_coord[1]) / 2]
}
