import {
  BubbleDataPoint,
  Chart,
  ChartOptions,
  ChartTypeRegistry,
  Point,
  TooltipModel,
} from 'chart.js'

interface BubbleChartExternalTooltip {
  chart: Chart<
    keyof ChartTypeRegistry,
    (number | BubbleDataPoint | Point | [number, number] | null)[],
    unknown
  >
  tooltip: TooltipModel<'bubble'>
}

export function horizonScanExternalTooltip() {
  return {
    enabled: false,
    external: (context: BubbleChartExternalTooltip) => {
      // Tooltip Element
      let tooltipEl = document.getElementById('chartjs-tooltip')

      // Create element on first render
      if (!tooltipEl) {
        tooltipEl = document.createElement('div')
        tooltipEl.id = 'chartjs-tooltip'
        tooltipEl.innerHTML = '<table></table>'
        document.body.appendChild(tooltipEl)
      }

      // Hide if no tooltip
      const tooltipModel = context.tooltip
      if (tooltipModel.opacity === 0) {
        tooltipEl.style.opacity = String(0)
        tooltipEl.style.display = 'none'
        return
      }

      tooltipEl.style.display = 'block'

      // Set caret Position
      tooltipEl.classList.remove('above', 'below', 'no-transform')
      if (tooltipModel.yAlign) {
        tooltipEl.classList.add(tooltipModel.yAlign)
      } else {
        tooltipEl.classList.add('no-transform')
      }

      function getBody(bodyItem: { lines: unknown }) {
        return bodyItem.lines
      }

      // Set Text
      if (tooltipModel.body) {
        const bodyLines = tooltipModel.body.map(getBody)

        let innerHtml = `
              <thead>
                <tr>                
                  <th class="colour-square-column" />
                  <th>PESTLE</th>
                  <th>Description</th>
                  <th>Certainty</th>
                </tr>
              </thead>
              <tbody>
              `

        bodyLines.forEach(function (_body, i) {
          // TODO: change and certainty isn't data driven
          const { change, certainty, pestle } = context.tooltip.dataPoints[i].raw as {
            change: string
            certainty: string
            pestle: string
          }

          const colors = tooltipModel.labelColors[i]
          const style = 'background-color:' + colors.backgroundColor + ' !important;'

          innerHtml += `
                <tr>
                  <td class="tooltip-square" style="${style}"></td>
                  <td>${pestle}</td>
                  <td>${change}</td>
                  <td>${certainty}</td>                  
                </tr>`
        })
        innerHtml += '</tbody>'

        const tableRoot = tooltipEl.querySelector('table')
        if (!tableRoot) return
        tableRoot.innerHTML = innerHtml

        // Tooltip container
        const position = context.chart.canvas.getBoundingClientRect()
        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1'
        tooltipEl.style.position = 'absolute'
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px'
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px'

        const tooltipHeight = tooltipEl.offsetHeight

        if (tooltipModel.caretY - tooltipHeight - 8 < 0) {
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 8 + 'px'
        } else {
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY - tooltipHeight - 8 + 'px'
        }

        tooltipEl.classList.add('bubble-chart-tooltip')
      }
    },
  }
}

interface BubbleChartDefaultOptions {
  isSmallChart?: boolean
  legendTitle?: string
  yAxisLabel?: string
  xAxisLabel?: string
  xSuggestedMax?: number
  ySuggestedMax?: number
}

export function defaultBubbleChartOptions({
  isSmallChart,
  legendTitle,
  yAxisLabel,
  xAxisLabel,
  xSuggestedMax,
  ySuggestedMax,
}: BubbleChartDefaultOptions): ChartOptions<'bubble'> {
  return {
    maintainAspectRatio: false,
    responsive: true,
    hover: {
      mode: 'nearest',
    },
    layout: {
      //https://www.chartjs.org/docs/latest/configuration/layout.html
      autoPadding: true,
      padding: isSmallChart ? 10 : 20,
    },
    plugins: {
      legend: {
        // https://www.chartjs.org/docs/latest/configuration/legend.html
        display: true,
        position: 'right',
        align: 'start',
        title: {
          display: true,
          position: 'center',
          text: legendTitle,
          padding: 2,
          font: {
            size: 14,
            weight: 'bold',
          },
        },
        labels: {
          padding: 12,
          boxWidth: isSmallChart ? 12 : 20,
          boxHeight: isSmallChart ? 12 : 20,
          borderRadius: 4,
          useBorderRadius: true,
          boxPadding: isSmallChart ? 2 : 4,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: ySuggestedMax,
        bounds: 'data',
        title: {
          text: yAxisLabel,
          padding: { bottom: isSmallChart ? 12 : 20 },
          display: true,
          font: {
            size: isSmallChart ? 12 : 16,
            weight: 'bold',
          },
        },
      },
      x: {
        beginAtZero: true,
        suggestedMax: xSuggestedMax,
        title: {
          text: xAxisLabel,
          display: true,
          padding: { top: isSmallChart ? 12 : 20 },
          font: {
            size: isSmallChart ? 12 : 16,
            weight: 'bold',
          },
        },
      },
    },
  }
}
