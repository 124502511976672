import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const bubbleChartContainer = ({ theme }: { theme: Theme }) => css`
  width: 100%;
  height: 100%;
  flex: 1 1 auto;
  color: ${theme.palette.text.primary};

  position: relative;
`
