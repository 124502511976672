import { Theme } from '@mui/material/styles'

import { tabMenuTitle } from './Tabs.styles'

export const getTabMenuProps = (index: number, theme: Theme) => {
  return {
    id: `tab-${index}`,
    css: tabMenuTitle({ theme }),
    'aria-controls': `tabpanel-${index}`,
  }
}
