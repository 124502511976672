/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {
  sideNavigationButtonsContainer,
  sideNavigationContainer,
  sideNavigationTitle,
} from './SideNavigation.styles'
import { SideNavigationButton, SideNavigationButtonProps } from './SideNavigationButton'

export interface SideNavigationProps {
  title?: string
  items: SideNavigationButtonProps[]
  activeItemTitle: string
  handleChangeActiveTitle: (title: string) => void
}

export const SideNavigation = ({
  title = 'Sidebar',
  items,
  activeItemTitle,
  handleChangeActiveTitle,
}: SideNavigationProps) => {
  const theme = useTheme()

  return (
    <Box css={sideNavigationContainer({ theme })}>
      <Typography variant="h6" css={sideNavigationTitle({ theme })}>
        {title.length ? title : 'Sidebar'}
      </Typography>
      <Box css={sideNavigationButtonsContainer({ theme })}>
        {items.map((item) => (
          <SideNavigationButton
            key={item.title}
            title={item.title}
            icon={item.icon}
            disabled={item.disabled}
            active={item.title === activeItemTitle}
            handleClick={() => handleChangeActiveTitle(item.title)}
          />
        ))}
      </Box>
    </Box>
  )
}
