/** @jsxImportSource @emotion/react */
import { useSnackbars } from '@contexts/SnackbarContext'
import { GenerateSelectedElementReport } from './Report'
import { Button, useTheme } from '@mui/material'
import { Print } from '@mui/icons-material'
import 'pdfmake/build/pdfmake'
import { useSelector } from 'react-redux'
import { RootState } from '@redux/store'
import { printButton } from './SelectedElementReport.styles'
import { MapboxGeoJSONFeature } from 'mapbox-gl'
import { getAssetAndHazardDetailsForSelectedFeature } from '../../MapView/RiskMapView.utilities'
import { AssetDetails } from '../Report.utils'
import { BASE_API_URL } from '@src/app-constants'
import axios from '@src/utils/customAxios'
import { Feature } from 'geojson'
import { usePreferences } from '@contexts/PreferencesContext'
const pdfMake = window['pdfMake']

pdfMake.fonts = {
  // Added 'X' to name to stop it being used by chart SVG which has 'Inter' defined as its font
  InterX: {
    normal: `${window.location.origin}/fonts/inter-v12-latin-regular.ttf`,
    italics: `${window.location.origin}/fonts/inter-v12-latin-regular.ttf`,
    bold: `${window.location.origin}/fonts/inter-v12-latin-800.ttf`,
  },
  Roboto: {
    // Roboto included as macrons cause severe issues in Inter
    normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
}

export const SelectedElementReportButton = () => {
  const theme = useTheme()
  const { displaySnackbar } = useSnackbars()
  const { layers, legendsData, selectedFeature } = useSelector((state: RootState) => state.map)
  const projectedExposureChartState = useSelector(
    (state: RootState) => state.projectedExposureChartState,
  )
  const { term_preference } = usePreferences()

  const handleClick = async () => {
    if (!selectedFeature) return

    const selectedMapboxGeoJsonFeature = selectedFeature as MapboxGeoJSONFeature

    displaySnackbar({
      message: 'Generating report...',
      type: 'info',
    })

    const assetLayers = layers.filter((layer) => layer.layerType == 'asset')
    const hazardLayers = layers.filter((layer) => layer.layerType == 'hazard')
    const selectedAssetLayer = assetLayers.find(
      (assetLayer) => assetLayer.tilesets[0].id === selectedMapboxGeoJsonFeature.layer.source,
    )

    if (!selectedAssetLayer) throw 'Selected asset layer not found'

    const selectedAssetDetails: AssetDetails | null =
      await getAssetAndHazardDetailsForSelectedFeature(
        selectedMapboxGeoJsonFeature,
        assetLayers,
        hazardLayers,
        legendsData,
      )

    if (!selectedAssetDetails) throw 'Asset details not found'

    const selectedAssetType = selectedAssetLayer?.type ?? ''
    const selectedAssetName = (selectedAssetDetails.asset?.name ?? 'Element')
      .replace('-', ' ')
      .replace(/\s+/g, '_')
    const selectedAssetId = selectedAssetDetails.asset?.id ?? 0
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    // MapBox's geojson is likely low res or partial
    const selectedFeatureFullGeoJson = await fetchAssetFullGeoJson(
      selectedMapboxGeoJsonFeature,
      selectedAssetType,
    )

    pdfMake
      .createPdf(
        await GenerateSelectedElementReport(
          selectedAssetDetails,
          selectedAssetLayer,
          selectedFeatureFullGeoJson,
          hazardLayers,
          term_preference.hazard,
          term_preference.risk,
          projectedExposureChartState,
        ),
      )
      .download(`${selectedAssetName}_${selectedAssetId}_Risk_Report_${year}-${month}-${day}`)
  }

  return (
    <>
      <Button css={printButton({ theme })} onClick={handleClick}>
        <Print fontSize="small" />
        &nbsp;&nbsp;Download Element Report
      </Button>
    </>
  )
}

const fetchAssetFullGeoJson = async (
  selectedFeature: MapboxGeoJSONFeature,
  selectedAssetType: string,
): Promise<Feature> => {
  const assetId: string = selectedFeature.properties?.asset_id

  return (await axios.get(`${BASE_API_URL}/api/asset/geojson/${selectedAssetType}/${assetId}`)).data
}
