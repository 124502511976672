/** @jsxImportSource @emotion/react */
import './BubbleChart.css'

import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
  BubbleDataPoint,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
  type ChartOptions,
} from 'chart.js'
import { useMemo } from 'react'
import { Bubble as ChartJSBubble } from 'react-chartjs-2'

import { bubbleChartContainer } from './BubbleChart.styles'
import { defaultBubbleChartOptions } from './BubbleChart.utils'

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend)

export type BubbleChartData<T> = BubbleDataPoint & T

export interface BubbleChartDataset<T> {
  label: string
  data: BubbleChartData<T>[]
  backgroundColor: string
  pointRadius: number
}

export interface BubbleChartProps<T> {
  datasets: BubbleChartDataset<T>[]
  legendTitle?: string
  xAxisLabel?: string
  yAxisLabel?: string
  isSmallChart?: boolean
  xSuggestedMax?: number
  ySuggestedMax?: number
  chartOptions?: ChartOptions<'bubble'>
}

export const BubbleChart = <T,>({
  datasets,
  chartOptions,
  legendTitle,
  xAxisLabel,
  yAxisLabel,
  xSuggestedMax,
  ySuggestedMax,
  isSmallChart = false,
}: BubbleChartProps<T>) => {
  const theme = useTheme()

  const options = useMemo(() => {
    return {
      ...defaultBubbleChartOptions({
        legendTitle,
        xAxisLabel,
        yAxisLabel,
        isSmallChart,
        xSuggestedMax,
        ySuggestedMax,
      }),
      ...chartOptions,
    }
  }, [
    chartOptions,
    isSmallChart,
    legendTitle,
    xAxisLabel,
    xSuggestedMax,
    yAxisLabel,
    ySuggestedMax,
  ])

  return (
    <Box css={bubbleChartContainer({ theme })}>
      <ChartJSBubble width="100%" height="100%" options={options} data={{ datasets }} />
    </Box>
  )
}
