/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import {
  legendCircle,
  legendContainer,
  legendItem,
  legendLabel,
  legendTitle,
} from './CircleSizeLegend.styles'

export const standardCircleLegendItems = [
  { size: 16, label: 'Low' },
  { size: 8, label: 'Moderate' },
  { size: 4, label: 'High' },
]

export interface CircleLegendItem {
  size: number
  label: string
}

export interface CircleSizeLegendProps {
  circleLegendItems?: CircleLegendItem[]
}

export const CircleSizeLegend = ({
  circleLegendItems = standardCircleLegendItems,
}: CircleSizeLegendProps) => {
  const theme = useTheme()

  return (
    <Box css={legendContainer({ theme })}>
      <Typography css={legendTitle({ theme })}>Certainty</Typography>
      {circleLegendItems.map((item) => (
        <Box key={item.label} css={legendItem({ theme })}>
          <Box css={legendCircle({ theme, size: item.size })} />
          <Typography variant="caption" css={legendLabel}>
            {item.label}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}
