import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const tableBodyContainer = ({ theme }: { theme: Theme }) => css`
  color: ${theme.palette.text.primary};

  & .MuiTableRow-root {
    &:hover {
      cursor: pointer;
    }
  }

  & .MuiTableCell-body {
    font-size: ${theme.typography.body1.fontSize};
  }
`

export const nonWrapText = css`
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const emptyRow = ({ theme }: { theme: Theme }) => css`
  height: ${theme.spacing(13)};
`
