import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface sectionContainerProps {
  theme: Theme
  padding: number
  center: boolean
}

export const sectionContainer = ({ theme, padding, center }: sectionContainerProps) => css`
  display: flex;
  flex-direction: column;

  justify-content: ${center ? 'center' : 'start'};
  align-items: ${center ? 'center' : 'flex-start'};

  padding: ${theme.spacing(padding)};
  border: 1px solid ${theme.palette.grey[200]};
  border-radius: ${theme.shape.borderRadius}px;
`
