/** @jsxImportSource @emotion/react */
import { css, ThemeProvider as EmotionThemeProvider, Global } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider, Theme } from '@mui/material/styles'
import { theme as UICLTheme } from '@styles/theme'

interface CustomThemeProviderProps {
  children: JSX.Element | React.ReactNode
  theme?: Theme
}

export const CustomThemeProvider = ({ children, theme }: CustomThemeProviderProps) => {
  return (
    <MuiThemeProvider theme={theme ?? UICLTheme}>
      <EmotionThemeProvider theme={theme ?? UICLTheme}>
        <Global
          styles={css`
            * {
              & * ::-webkit-scrollbar {
                width: 5px !important;
                height: 5px !important;
                border-radius: 4px !important;
              }

              & * ::-webkit-scrollbar-track {
                border-radius: 12px !important;

                background: #d6d6d690 !important;
                border: 1px solid #ebebeb !important;
              }

              & * ::-webkit-scrollbar-thumb {
                border-radius: 8px !important;
                background-color: #c2c2c2 !important;

                transition: background-color 0.2s !important;

                &:hover {
                  background-color: #adadad !important;
                }

                & * ::-webkit-scrollbar {
                  width: 5px !important;
                  height: 5px !important;
                  border-radius: 4px !important;
                }
              }
            }
          `}
        />

        <CssBaseline />
        {children}
      </EmotionThemeProvider>
    </MuiThemeProvider>
  )
}
