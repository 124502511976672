/** @jsxImportSource @emotion/react */
import {
  Autocomplete,
  Box,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  IconButton,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Icon } from '@src/components/Atoms/Icon'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useSWR from 'swr'

import { setSideDrawerIsOpen } from '../../../redux/sideDrawer/sideDrawerSlice'
import {
  chartContainer,
  horizonScanContainer,
  leftSection,
  rightSection,
  sectionContent,
  sectionContentList,
  sectionHeader,
  sectionStyle,
  selectContainer,
  selectInput,
  timeframeDropdownContainer,
  timeframeDropdownInfoButton,
  toggleButtonContainer,
} from './HorizonScan.styles'
import {
  CurrentScenarioOption,
  SelectClimateRelatedOption,
  SelectRiskCategoryOption,
  SelectScenarioOption,
  SelectTimeframeOption,
  filterByConditions,
  getRiskSummaryData,
  getTopTenThreatsAndOpportunities,
  selectClimateRelatedOptions,
  selectRiskCategoryOptions,
  selectScenarioOptions,
  timeframeOptions,
} from './HorizonScan.utils'
import { HorizonScanLoading } from './HorizonScanLoading'
import { HorizonScanMultipleBubbleCharts } from './HorizonScanMultipleBubbleCharts'
import { usePreferences } from '@contexts/PreferencesContext'
import { Section } from '@src/components/Atoms/Section'
import {
  BubbleChart,
  BubbleChartDataset,
  defaultBubbleChartOptions,
  horizonScanExternalTooltip,
} from '@src/components/Molecules/Charts/BubbleChart'
import { CircleSizeLegend } from '@src/components/Molecules/CircleSizeLegend'
import { SideNavigation, SideNavigationButtonProps } from '@src/components/Organisms/SideNavigation'
export const scenarioOptions = ['current', 'hothouse', 'disorderly', 'orderly'] as const
export type ScenarioOption = (typeof scenarioOptions)[number]

export const scenarioTypes = ['threat', 'opportunity'] as const
export type ScenarioType = (typeof scenarioTypes)[number]

export type HorizonScanItem = {
  division: string
  climate_scenario: ScenarioOption
  pestle: string
  threat_opportunity: string
  change: string
  opportunity_risk: string
  control: string
  climate_related: string
  x: number
  y: number
  r: number
  certainty: string
  risk_category: string
  timeframe: string
  score: number
  rank: number
  overlaps: HorizonScanItem[] | null
}

const sideNavigationButtons: SideNavigationButtonProps[] = [
  {
    title: 'Horticulture',
    icon: 'Horticulture',
  },
  {
    title: 'Proteins',
    icon: 'Proteins',
  },
  {
    title: 'Logistics',
    icon: 'Logistics',
  },
  {
    title: 'Corporation',
    icon: 'Corporation',
  },
]

const currentScenarioOption = { label: 'Current', value: 'current' }

export const HorizonScan = () => {
  const theme = useTheme()
  const { term_preference } = usePreferences()
  const dispatch = useDispatch()
  const { data, isLoading } = useSWR('/horizonScanData', getRiskSummaryData)

  const [timeframe, setTimeframe] = useState<SelectTimeframeOption>(timeframeOptions[0])
  const [chartData, setChartData] = useState<BubbleChartDataset<HorizonScanItem>[]>([])
  const [scenarioType, setScenarioType] = useState<ScenarioType>('threat')

  const [activeDivision, setActiveDivision] = useState(sideNavigationButtons[0].title)

  const [topTenThreats, setTopTenThreats] = useState<HorizonScanItem[]>()
  const [topTenOpportunities, setTopTenOpportunities] = useState<HorizonScanItem[]>()

  const [scenarioOption, setScenarioOption] = useState<
    SelectScenarioOption | CurrentScenarioOption
  >(selectScenarioOptions[0])

  const [climateRelated, setClimateRelated] = useState<SelectClimateRelatedOption>(
    selectClimateRelatedOptions[0],
  )

  const [riskCategory, setRiskCategory] = useState<SelectRiskCategoryOption>(
    selectRiskCategoryOptions[0],
  )

  const horizonScanBubbleChartProps = {
    xSuggestedMax: 5.5,
    ySuggestedMax: 5.5,
    chartOptions: {
      plugins: {
        ...defaultBubbleChartOptions({
          legendTitle: 'PESTLE',
          xAxisLabel: 'Likelihood',
          yAxisLabel: term_preference.risk ? 'Risk' : 'Consequence',
        }).plugins,
        tooltip: horizonScanExternalTooltip(),
      },
    },
  }

  const formatChartData = useCallback(async (data: HorizonScanItem[]) => {
    return new Promise<BubbleChartDataset<HorizonScanItem>[]>((resolve) => {
      // TODO: make this data-driven
      const types = [
        { label: 'Environment', colour: '#089533b7' },
        { label: 'Social', colour: '#06aec0b5' },
        { label: 'Legal/Reg', colour: '#e8b32ebb' },
        { label: 'Technological', colour: '#183946b7' },
        { label: 'Economic', colour: '#b13da7b8' },
        { label: 'Political', colour: '#ed1d1db9' },
      ]

      const chartByTypes: BubbleChartDataset<HorizonScanItem>[] = types.map((type) => {
        const filteredByType = data.filter(
          ({ pestle }) => pestle.trim().toLowerCase() === type.label.trim().toLowerCase(),
        )
        return {
          label: type.label,
          data: filteredByType,
          backgroundColor: type.colour,
          pointRadius: 10,
        }
      })

      resolve(chartByTypes)
    })
  }, [])

  const formatAndSetState = useCallback(
    async (data: HorizonScanItem[]) => {
      const conditions: { [key: string]: string }[] = [
        { threat_opportunity: scenarioType },
        { climate_scenario: scenarioOption.value },
        { risk_category: riskCategory.value },
        { climate_related: climateRelated.value },
        { division: activeDivision },
      ]

      let filteredData = await filterByConditions(data, conditions)

      if (timeframe.value !== 'current') {
        filteredData = filteredData.filter((item) => item.climate_scenario !== 'current')
      }

      const chartByTypes = await formatChartData(filteredData)

      return new Promise<BubbleChartDataset<HorizonScanItem>[]>((resolve) => {
        resolve(chartByTypes)
      })
    },
    [
      scenarioType,
      scenarioOption.value,
      riskCategory.value,
      climateRelated.value,
      activeDivision,
      timeframe.value,
      formatChartData,
    ],
  )

  const handleUpdateSideDrawer = useCallback(() => {
    // eslint-disable-next-line no-console
    console.log(
      'scenario_option_detail',
      { scenario: scenarioOption.label, division: activeDivision } ?? selectScenarioOptions[0],
    )
  }, [activeDivision, scenarioOption.label])

  useEffect(() => {
    handleUpdateSideDrawer()
  }, [activeDivision, dispatch, handleUpdateSideDrawer, scenarioOption])

  useEffect(() => {
    if (!data) return

    const hydrateDataAndSetState = async () => {
      const chartDataByTypes = await formatAndSetState(data)

      setChartData(chartDataByTypes)

      const conditions: { [key: string]: string }[] = [
        { climate_scenario: scenarioOption.value },
        { risk_category: riskCategory.value },
        { climate_related: climateRelated.value },
        { division: activeDivision },
      ]

      const topTenResults = await getTopTenThreatsAndOpportunities({
        data,
        conditions,
        timeframe,
      })

      setTopTenThreats(topTenResults.topTenThreatsData)
      setTopTenOpportunities(topTenResults.topTenOpportunitiesData)
    }

    hydrateDataAndSetState()
  }, [
    data,
    formatAndSetState,
    scenarioOption.value,
    riskCategory.value,
    timeframe.value,
    climateRelated.value,
    activeDivision,
    timeframe,
  ])

  useEffect(() => {
    if (climateRelated.value === 'no') {
      setRiskCategory(selectRiskCategoryOptions[0])
    }

    if (timeframe.value === 'current') {
      setScenarioOption(currentScenarioOption)
    }

    if (timeframe.value !== 'current' && scenarioOption.value === 'current') {
      setScenarioOption(selectScenarioOptions[0])
    }
  }, [climateRelated.value, scenarioOption.value, timeframe])

  if (isLoading || !topTenOpportunities) return <HorizonScanLoading />

  return (
    <>
      <SideNavigation
        title="Sidebar"
        items={sideNavigationButtons}
        activeItemTitle={activeDivision}
        handleChangeActiveTitle={setActiveDivision}
      />

      <Box css={horizonScanContainer({ theme })}>
        <Box css={leftSection({ theme })}>
          <Section paddingSize="none" css={sectionStyle}>
            <Box css={sectionHeader({ theme })}>
              <Typography>
                Top 10 <strong>opportunities</strong>
              </Typography>
            </Box>
            <Box css={sectionContent({ theme })}>
              <Box component="ul" css={sectionContentList({ theme })}>
                {topTenOpportunities?.map((opportunity, index) => (
                  <li key={index}>{opportunity.change}</li>
                ))}
              </Box>
            </Box>
          </Section>
          <Section paddingSize="none" css={sectionStyle}>
            <Box css={sectionHeader({ theme })}>
              <Typography>
                Top 10 <strong>threats</strong>
              </Typography>
            </Box>
            <Box css={sectionContent({ theme })}>
              <Box component="ul" css={sectionContentList({ theme })}>
                {topTenThreats?.map((threat, index) => (
                  <li key={index}>{threat.change}</li>
                ))}
              </Box>
            </Box>
          </Section>
        </Box>

        <Box css={rightSection({ theme })}>
          <Stack direction="row" gap="12px" justifyContent="start">
            <Box css={selectContainer({ theme })}>
              <Autocomplete
                css={selectInput}
                id="timeframe"
                renderInput={(params) => <TextField {...params} label="Timeframe" />}
                value={timeframe}
                options={timeframeOptions}
                getOptionLabel={(option) => option.label}
                onChange={(_e, option) => {
                  if (option?.value === 'current') {
                    // eslint-disable-next-line no-console
                    console.log('Empty Side Drawer Content')
                    setScenarioOption(selectScenarioOptions[0])
                  }
                  setTimeframe(option ?? timeframeOptions[0])
                }}
              />
              {timeframe.value !== 'current' ? (
                <Box css={timeframeDropdownContainer({ theme })}>
                  <Autocomplete
                    css={selectInput}
                    renderInput={(params) => <TextField {...params} label="Scenario Option" />}
                    value={scenarioOption}
                    options={selectScenarioOptions}
                    onChange={(_e, value) => setScenarioOption(value ?? selectScenarioOptions[0])}
                  />
                  <IconButton
                    css={timeframeDropdownInfoButton({ theme })}
                    onClick={() => {
                      handleUpdateSideDrawer()
                      dispatch(setSideDrawerIsOpen(true))
                    }}
                  >
                    <Icon iconName="Info" colour={theme.palette.grey[500]} />
                  </IconButton>
                </Box>
              ) : null}

              <Autocomplete
                css={selectInput}
                renderInput={(params) => <TextField {...params} label="Climate Related" />}
                value={climateRelated}
                options={selectClimateRelatedOptions}
                onChange={(_e, value) => setClimateRelated(value ?? selectClimateRelatedOptions[0])}
              />
              {climateRelated.value !== 'no' && (
                <Autocomplete
                  css={selectInput}
                  renderInput={(params) => <TextField {...params} label="Risk Category" />}
                  value={riskCategory}
                  options={selectRiskCategoryOptions}
                  onChange={(_e, value) => setRiskCategory(value ?? selectRiskCategoryOptions[0])}
                />
              )}
            </Box>
            <ToggleButtonGroup
              value={scenarioType}
              exclusive
              onChange={(_e, type) => setScenarioType(type ?? 'threat')}
              aria-label="scenario type"
              css={toggleButtonContainer({ theme })}
            >
              <ToggleButton value="threat" aria-label="threat">
                <Typography>Threats</Typography>
              </ToggleButton>
              <ToggleButton value="opportunity" aria-label="opportunity">
                <Typography>Opportunities</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Box css={chartContainer({ theme })}>
            {scenarioOption.value === 'all' ? (
              <HorizonScanMultipleBubbleCharts data={chartData} />
            ) : (
              <Box css={chartContainer({ theme })}>
                <BubbleChart {...horizonScanBubbleChartProps} datasets={chartData} />
                <CircleSizeLegend />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
