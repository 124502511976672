import { Map } from 'mapbox-gl'
import { copyBlobToClipboard } from './clipboard'
import { UserLogos } from '@redux/user/userSlice'
import { MapLayer, MapLocation } from '@redux/map/mapSlice'
import { Theme } from '@mui/material'
import axios from '@src/utils/customAxios'
import { PinSvg, stripWidthAndHeightFromSvg } from '@src/components/Molecules/MapView/LayerTypes'
import { PUBLIC_ASSETS_BASE_URL } from '@src/app-constants'
import { LegendsData } from '@src/components/Molecules/MapView/AddLayersDialog'
import { createHiddenDivAndAddToPage, getMapImageAsBlob, deleteDiv } from './mapExporterUtils'
import { generateVulnerabilityPalette } from '@src/components/Molecules/MapView/RiskMapView.utilities'

export const exportMapToPdf = async (
  theMap: Map,
  clientDisplayName: string,
  layers: MapLayer[],
  legendsData: LegendsData,
  logos: UserLogos,
  mainColor: string,
) => {
  const { generateDimensions, generatePdfFromBlob } = await import('./pdfGenerator')
  const mapDimensions = generateDimensions('A4')
  const aspectRatio = mapDimensions.mapWidth / mapDimensions.mapHeight
  const vulnerabilityPalette = generateVulnerabilityPalette(legendsData, 'vulnerability')
  await preparePageDuplicateMapAndExportImageBlob(
    theMap,
    2.0,
    aspectRatio,
    async (imageBlob) => {
      await generatePdfFromBlob(
        imageBlob,
        theMap,
        clientDisplayName,
        layers,
        legendsData,
        logos,
        mainColor,
      )
    },
    vulnerabilityPalette,
  )
}

export const exportMapToClipboard = async (theMap: Map, vulnerabilityPalette: string[]) => {
  const aspectRatio = theMap.getCanvas().width / theMap.getCanvas().height

  await preparePageDuplicateMapAndExportImageBlob(
    theMap,
    1.0,
    aspectRatio,
    async (imageBlob) => {
      copyBlobToClipboard(imageBlob)
    },
    vulnerabilityPalette,
  )
}

export const preparePageDuplicateMapAndExportImageBlob = async (
  theMap: Map,
  imageScale: number,
  aspectRatio: number,
  imageBlobOutputHandler: (imageBlob: Blob) => Promise<void>,
  vulnerabilityPalette: string[],
  selectedElementMarkerLocation: MapLocation | null = null,
  theme: Theme | null = null,
) => {
  const currentWindowScale = getWindowScale()
  scaleWindow(currentWindowScale * imageScale)

  const mapWidth = theMap.getCanvas().width
  const mapHeight = mapWidth / aspectRatio

  const hiddenDiv = createHiddenDivAndAddToPage(mapWidth, mapHeight)

  const mapCopy = await duplicateMap(theMap, hiddenDiv, vulnerabilityPalette)

  if (selectedElementMarkerLocation && theme) {
    await addSelectedElementMarkerToMap(mapCopy, selectedElementMarkerLocation, theme)
  }

  const mapImageBlob = await getMapImageAsBlob(mapCopy)

  await imageBlobOutputHandler(mapImageBlob)

  mapCopy.remove()
  deleteDiv(hiddenDiv)

  scaleWindow(currentWindowScale)
}

// The selected element marker on the OG map is an html element that sits on top of the map canvas
// so to get this to render on the map canvas we need to add an icon layer
const addSelectedElementMarkerToMap = async (
  map: Map,
  selectedElementMarkerLocation: MapLocation,
  theme: Theme,
) => {
  const selectedElementIconResponse = await axios.get<string>(
    `${PUBLIC_ASSETS_BASE_URL}/icons/selected_element.svg`,
    {
      withCredentials: false,
    },
  )
  const selectedElementIconSvg = stripWidthAndHeightFromSvg(selectedElementIconResponse.data)

  const pinSvg = PinSvg.replace('<!--ICONSVG-->', selectedElementIconSvg).replace(
    '#777777',
    theme.palette.primary.main,
  )

  const svgBitmap = new Image(42, 51)
  svgBitmap.onload = () => {
    map.addImage('selecterElementMarker', svgBitmap)
  }
  svgBitmap.src = 'data:image/svg+xml;base64,' + btoa(pinSvg)

  map.addSource('selectedElementLocationSource', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [
              selectedElementMarkerLocation.longitude,
              selectedElementMarkerLocation.latitude,
            ],
          },
        },
      ],
    },
  })

  map.addLayer({
    id: 'selectedElementLocation',
    type: 'symbol',
    source: 'selectedElementLocationSource',
    layout: {
      'icon-image': 'selecterElementMarker',
      'icon-size': 2, // Make it twice as big
      'icon-anchor': 'bottom',
    },
  })

  // Wait for the layer to load
  return new Promise<void>(function (resolve) {
    map.on('idle', () => {
      resolve()
    })
  })
}

export const blobToDataUrl = async (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onloadend = () => {
      if (reader.result) resolve(reader.result.toString())
      else reject()
    }
    reader.readAsDataURL(blob)
  })

const duplicateMap = async (
  theMap: Map,
  hiddenDiv: HTMLDivElement,
  vulnerabilityPalette: string[],
): Promise<Map> =>
  new Promise((resolve) => {
    const mapCopy = new Map({
      container: hiddenDiv,
      style: theMap.getStyle(),
      center: theMap.getCenter(),
      bearing: theMap.getBearing(),
      pitch: theMap.getPitch(),
      zoom: theMap.getZoom(),
    })

    // Icon layer images aren't replicated above and need to be loaded separately.
    // I went round-the-houses trying to reload the images in all sorts of ways but
    // had major issues with race conditions and daft logic so settled on loading
    // an image when it throws a not found error as it plays nice with the on idle event.
    mapCopy.on('styleimagemissing', async (e) => {
      const vulnerabilityIconId = e.id as string

      // vulnerabilityIconId should look like 'water_house-3' for example where the number after
      // the hyphen is the vulnerability value
      const hyphenPos = vulnerabilityIconId.lastIndexOf('-')
      if (hyphenPos == -1) return

      const iconId = vulnerabilityIconId.substring(0, hyphenPos)
      const vulnerability = vulnerabilityIconId.substring(hyphenPos + 1)
      if (vulnerability !== 'default' && Number.isNaN(Number(vulnerability))) return

      let pinColor = ''
      if (vulnerability == 'default') pinColor = '#0b2a48'
      else pinColor = vulnerabilityPalette[Number(vulnerability)]

      if (Number.isNaN(vulnerability)) return

      const response = await axios.get<string>(`${PUBLIC_ASSETS_BASE_URL}/icons/${iconId}.svg`, {
        withCredentials: false,
      })

      const iconSvg = stripWidthAndHeightFromSvg(response.data)
      let pinSvg = PinSvg
      pinSvg = pinSvg.replace('<!--ICONSVG-->', iconSvg)

      const svgBitmap = new Image(42, 51)
      svgBitmap.onload = () => {
        if (!mapCopy.hasImage(e.id)) mapCopy.addImage(e.id, svgBitmap)
      }
      svgBitmap.src = 'data:image/svg+xml;base64,' + btoa(pinSvg.replace('#777777', pinColor))
    })

    mapCopy.once('idle', () => {
      resolve(mapCopy)
    })
  })

function getWindowScale() {
  return window.devicePixelRatio
}

function scaleWindow(scale: number) {
  Object.defineProperty(window, 'devicePixelRatio', {
    get: function () {
      return scale
    },
  })
}
