/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
  FormControlLabel,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  Radio,
} from '@mui/material'

import { radioGroupMainClass } from './RadioGroup.styles'

export type RadioGroupOption = {
  label: string
  value: string | number
  disabled?: boolean
  selected?: boolean
}

export type RadioGroupProps = {
  options: RadioGroupOption[]
  className?: string
} & MuiRadioGroupProps

export const RadioGroup = ({ options, className, ...rest }: RadioGroupProps) => {
  const selectedValue = options.find((option) => option.selected)?.value

  const optionsComponents = (
    <>
      {options.map((option) => (
        <FormControlLabel
          disabled={option.disabled}
          key={option.value}
          value={option.value}
          control={
            <Radio
              size="small"
              css={css`
                padding: 4px;
              `}
            />
          }
          css={css`
            margin-left: -6px;
            margin-right: 0px;
          `}
          label={option.label}
        />
      ))}
    </>
  )

  return (
    <MuiRadioGroup
      data-testid="radioGroup"
      defaultValue={selectedValue}
      {...rest}
      css={radioGroupMainClass}
      className={className}
    >
      {optionsComponents}
    </MuiRadioGroup>
  )
}
