/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Theme } from '@mui/material'

export const legendContainer = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  bottom: ${theme.spacing(1)};
  right: ${theme.spacing(1)};
  z-index: 1;

  padding: ${theme.spacing(1)} ${theme.spacing(3)};

  background-color: ${theme.palette.background.paper};

  border-radius: ${theme.shape.borderRadius}px;
  border: 1px solid ${theme.palette.grey[200]};
  overflow: hidden;
`

export const legendItem = ({ theme }: { theme: Theme }) => css`
  z-index: 0;

  display: grid;
  grid-template-columns: ${theme.spacing(6)} max-content;
  grid-template-rows: auto;

  column-gap: ${theme.spacing(2)};

  margin-bottom: ${theme.spacing(1)};
  align-items: center;
`

export const legendCircle = ({ theme, size }: { theme: Theme; size: number }) => css`
  width: ${size}px;
  height: ${size}px;
  background-color: ${theme.palette.grey[500]};
  border-radius: 50%;

  justify-self: center;
`

export const legendLabel = css`
  justify-self: start;
  align-self: center;
`

export const legendTitle = ({ theme }: { theme: Theme }) => css`
  text-align: center;
  font-weight: bold;
  margin-bottom: ${theme.spacing(1)};
`
