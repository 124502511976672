/** @jsxImportSource @emotion/react */
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useId } from 'react'

export type DropdownProps = {
  handleChange: (event: SelectChangeEvent) => void
  label: string
  value: string
  children: JSX.Element | React.ReactNode
}

export const Dropdown = ({ handleChange, label, value, children }: DropdownProps) => {
  const labelId = useId()
  return (
    <FormControl fullWidth style={{ marginTop: '4px' }}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select label={label} onChange={handleChange} labelId={labelId} value={value}>
        {children}
      </Select>
    </FormControl>
  )
}

export type DropdownItemProps = {
  value: number | string
  label: string
}

export const DropdownItem = ({ value, label }: DropdownItemProps) => (
  <MenuItem value={value}>{label}</MenuItem>
)
