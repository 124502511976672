import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface SideNavigationContainerProps {
  theme: Theme
}

export const sideNavigationTitle = ({ theme }: { theme: Theme }) => css`
  font-weight: 700;
  text-align: center;

  padding: ${theme.spacing(3)} 0;

  color: ${theme.palette.primary.main};

  border-bottom: 1px solid ${theme.palette.grey[200]};
  flex-shrink: 0;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const sideNavigationContainer = ({ theme }: SideNavigationContainerProps) => css`
  height: 100%;
  width: 240px;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  padding: ${theme.spacing(2)};
  background-color: ${theme.palette.white.main};

  border-right: 1px solid ${theme.palette.grey[200]};
  box-shadow: ${theme.shadows[1]};

  z-index: 10;

  overflow: hidden;
`

export const sideNavigationButtonsContainer = ({ theme }: SideNavigationContainerProps) => css`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(1)};

  overflow-y: auto;
`
