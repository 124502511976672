/** @jsxImportSource @emotion/react */
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { tabContent } from './TabContent.styles'

interface TabPanelProps {
  index: number
  tabIndex: number
  children?: JSX.Element | React.ReactNode
}

export const TabContent = ({ children, tabIndex, index, ...rest }: TabPanelProps) => {
  const theme = useTheme()

  return (
    <Box
      id={`tabpanel-${index}`}
      role="tabpanel"
      hidden={tabIndex !== index}
      aria-labelledby={`tab-${index}`}
      css={tabContent({ theme })}
      {...rest}
    >
      {tabIndex === index && children}
    </Box>
  )
}
