/** @jsxImportSource @emotion/react */

import './App.css'

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Popover,
  createTheme,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { BASE_API_URL } from '@constants'
import { useFeatureFlags } from '@contexts/FeatureFlagsContext'
import { UserState, setUserAcceptedTsncs, updateUserStateProperty } from '@redux/user/userSlice'
import { Button } from '@src/components/Atoms/Button'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { RootState } from '@redux/store'
import { MapLocation, updateMapStateProperty } from '@redux/map/mapSlice'
import {
  appContainer,
  appContentContainer,
  notificationRedDot,
  removeHover,
  sidebarContainer,
} from './App.styles'
import { getSidebarMiddleMenuLinks } from './App.utils'
import { TsnCsModal } from '@src/components/Organisms/Modals/TsnCsModal'
import { useCookies } from 'react-cookie'
import axios from '@src/utils/customAxios'
import { AppAuthHolder } from '@src/components/Molecules/AppAuthHolder/AppAuthHolder'
import { ChangeClient } from '@src/components/Organisms/Modals/SignInModal/ChangeClient/ChangeClient'
import { ReleaseNotesModal } from '@src/components/Organisms/Modals/ReleaseNotesModal/ReleaseNotesModal'
import { Sidebar, SidebarMenuButton } from '@src/components/Organisms/Sidebar'
import { Tutorial } from '@src/components/Organisms/Tutorials'
import { useTutorialContext } from '@contexts/TutorialContext'
import { SubmitFeedbackModal } from '@src/components/Organisms/Modals/SubmitFeedbackModal/SubmitFeedbackModal'
import { theme } from '@styles/theme'
import { CustomThemeProvider } from '@src/components/Atoms/CustomThemeProvider'

export type UserConfigResponse = UserState & {
  default_location: MapLocation
}

export async function loader() {
  const data = setTimeout(() => {
    return 'finish'
  }, 0)
  return data
}

interface Settings {
  tsncs_html: string
}

export const App = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { features } = useFeatureFlags()
  const { pathname } = useLocation()
  const { tutorialActive, tutorialToRun, setTutorialToRun, setTutorialActive } =
    useTutorialContext()

  const userStore = useSelector((state: RootState) => state.user)
  const {
    availableClients,
    clientName,
    clientDisplayName,
    userRole,
    logos: storeLogos,
    theme: storeTheme,
    feedback_url,
    tsncs_html: client_tsncs_html,
    accepted_tsncs,
    show_changelog,
    show_feedback_modal,
  } = userStore

  const [showReleaseDialog, setShowReleaseDialog] = useState(false)
  const [showNotificationRedDot, setShowNotificationRedDot] = useState(false)
  const [authModal, setAuthModal] = useState(false)
  const [logoutDialog, setLogoutDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [settings, setSettings] = useState<Settings>({ tsncs_html: '' })

  const initialLoadHolder = useRef({
    legends: '',
    settings: '',
  })

  const [cookies, setCookie] = useCookies(['tsncs_accepted', 'client_name', 'explorerApiSession'])

  const isUserLoggedIn = !!clientName.length

  const releaseDialogCheck = useCallback(() => {
    if (show_changelog) return true
  }, [show_changelog])

  const postChangelogSeen = () => {
    try {
      axios.post(`${BASE_API_URL}/api/user/changelog_seen`)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error posting changelog seen update', error)
    }
  }

  useEffect(() => {
    if (releaseDialogCheck()) {
      setShowNotificationRedDot(true)
    }
  }, [releaseDialogCheck])

  const clientTheme = useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            ...theme.palette.primary,
            ...storeTheme.primary,
          },
          secondary: {
            ...theme.palette.secondary,
            ...storeTheme.secondary,
          },
        },
      }),
    [storeTheme],
  )

  const sidebarMiddleMenu = getSidebarMiddleMenuLinks({
    pathname,
    navigate,
    featuresFlags: features,
    clientName,
  })

  const sidebarBottomMenuWithoutUser: SidebarMenuButton[] = [
    {
      label: clientName ? 'Logout' : 'Login',
      icon: clientName ? 'SignOut' : 'SignIn',
      testId: 'loginButton',
      active: false,
      onClick: clientName ? () => setLogoutDialog(true) : () => setAuthModal(true),
    },
  ]

  const tutorials: { [key: string]: string } = {
    '/risk': 'RiskPageTutorial',
  }

  const sidebarBottomMenu = () => {
    const bottomMenuLinks: SidebarMenuButton[] = [
      {
        label: 'Tutorial',
        icon: 'Help',
        active: false,
        onClick: () => {
          if (tutorials[pathname]) {
            setTutorialToRun(tutorials[pathname])
            setTutorialActive(true)
          }
        },
        hidden: !tutorials[pathname],
      },
      {
        label: 'Notifications',
        icon: 'NotificationsIcon',
        active: false,
        onClick: () => setShowReleaseDialog(!showReleaseDialog),
        customClass: {
          iconClass: showNotificationRedDot ? notificationRedDot : undefined,
        },
      },
      {
        label: clientDisplayName,
        icon: 'People',
        active: false,
        customClass: {
          buttonClass: availableClients.length < 2 ? removeHover : undefined,
        },
        id: 'clientSelectPopoverButton',
        onClick:
          availableClients.length > 1
            ? (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)
            : () => null,
      },

      ...sidebarBottomMenuWithoutUser,
    ]
    if (userRole === 'admin') {
      bottomMenuLinks.unshift({
        label: 'Manage Users',
        icon: 'Key',
        active: pathname === '/manage-users',
        onClick: () => navigate('manage-users'),
      })
    }
    return bottomMenuLinks
  }

  useEffect(() => {
    if (!clientName) setAuthModal(true)
  }, [clientName, pathname])

  useEffect(() => {
    if (initialLoadHolder.current.settings !== clientName) {
      axios.get(`${BASE_API_URL}/api/settings`).then((response) => {
        setSettings(response.data)
        initialLoadHolder.current.settings = clientName
      })
    }

    if (clientName && initialLoadHolder.current.legends !== clientName) {
      axios
        .get(`${BASE_API_URL}/api/legends`)
        .then((response) => dispatch(updateMapStateProperty({ legendsData: response.data })))
      initialLoadHolder.current.legends = clientName
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientName, dispatch])

  const handleClientAcceptTsnCs = async () => {
    await axios.post(`${BASE_API_URL}/api/user/accept_tsncs`)
    dispatch(setUserAcceptedTsncs(true))
  }

  return (
    <CustomThemeProvider theme={clientTheme}>
      <Box css={appContainer}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box css={sidebarContainer}>
            <Sidebar
              logos={[
                {
                  title: clientDisplayName,
                  reducedLogo: storeLogos.whiteSimple,
                  fullLogo: storeLogos.whiteFull,
                  logoBackgroundColour: 'transparent',
                  onClick: () => navigate('/'),
                },
              ]}
              feedback_url={feedback_url}
              middleMenu={sidebarMiddleMenu}
              bottomMenu={isUserLoggedIn ? sidebarBottomMenu() : sidebarBottomMenuWithoutUser}
              appVersion={APP_VERSION}
              setShowFeedbackModal={(flag) =>
                dispatch(updateUserStateProperty({ show_feedback_modal: flag }))
              }
            />
          </Box>
          <Popover
            id={anchorEl ? 'clientSelectPopover' : undefined}
            open={anchorEl?.id === 'clientSelectPopoverButton'}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          >
            <Card sx={{ width: '100%' }}>
              <CardHeader title="Change Client" />
              <CardContent sx={{ width: '100%', paddingTop: 0 }}>
                <ChangeClient onSelect={() => setAnchorEl(null)} />
              </CardContent>
              <CardActions>
                <Button size="small" variant="text" onClick={() => setAnchorEl(null)}>
                  Close
                </Button>
              </CardActions>
            </Card>
          </Popover>

          <Box css={appContentContainer}>
            <Outlet />
          </Box>
        </LocalizationProvider>
      </Box>
      {createPortal(
        <AppAuthHolder
          showLogoutDialog={logoutDialog}
          handleLogoutDialogClose={() => setLogoutDialog(false)}
          handleOpen={() => setAuthModal(true)}
          handleClose={() => setAuthModal(false)}
          open={authModal}
        />,
        document.body,
      )}
      {settings.tsncs_html &&
        settings.tsncs_html !== '' &&
        (!cookies.tsncs_accepted || cookies.tsncs_accepted !== 'true') &&
        createPortal(
          <TsnCsModal
            content={settings.tsncs_html}
            handleAcceptTsnCs={() => {
              setCookie('tsncs_accepted', 'true', {
                path: '/',
                maxAge: 60 * 60 * 24 * 365,
              })
            }}
          />,
          document.body,
        )}
      {client_tsncs_html &&
        !import.meta.env.VITE_ISLOCAL &&
        !accepted_tsncs &&
        cookies.tsncs_accepted &&
        cookies.tsncs_accepted == 'true' &&
        !authModal &&
        createPortal(
          <TsnCsModal
            content={client_tsncs_html}
            handleAcceptTsnCs={handleClientAcceptTsnCs}
            showClientLogo={true}
          />,
          document.body,
        )}
      {showReleaseDialog &&
        createPortal(
          <ReleaseNotesModal
            open={showReleaseDialog}
            handleClose={() => {
              setShowReleaseDialog(false)
              setShowNotificationRedDot(false)
              postChangelogSeen()
            }}
          />,
          document.body,
        )}
      {show_feedback_modal &&
        createPortal(
          <SubmitFeedbackModal
            open={show_feedback_modal}
            handleClose={(flag) => {
              dispatch(updateUserStateProperty({ show_feedback_modal: flag ?? false }))
            }}
          />,
          document.body,
        )}
      <Tutorial tutorialToRun={tutorialToRun ?? 'RiskPageTutorial'} showTutorial={tutorialActive} />
    </CustomThemeProvider>
  )
}
