import { ImgHTMLAttributes } from 'react'

import { Solver } from './IconFromUrl.utils'

export interface IconEmbeddedProps extends ImgHTMLAttributes<HTMLImageElement> {
  colour: string
  src: string
}

export const IconEmbedded = ({ src, colour, ...rest }: IconEmbeddedProps) => {
  const solver = new Solver(colour)
  const result = solver.solve()

  return <img src={src} style={{ filter: result.filter }} {...rest} />
}
