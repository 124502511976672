/** @jsxImportSource @emotion/react */
import { Tab as MuiTab, Tabs as MuiTabs } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'

import { topNavbarContainer } from './TopNavbar.styles'

export interface LinkTabProps {
  label: string
  value: string
  disabled?: boolean
  handleClick: () => void
}

export interface TopNavbarProps {
  links: LinkTabProps[]
  initialActiveLink: string
}

const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')

export const TopNavbar = ({ links, initialActiveLink }: TopNavbarProps) => {
  const theme = useTheme()
  const [value, setValue] = useState(initialActiveLink)

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const tabProps = (label: string) => {
    return {
      id: `top-navbar-${slugify(label)}`,
      'aria-controls': `top-navbar-to-${slugify(label)}`,
    }
  }

  return (
    <MuiTabs
      css={topNavbarContainer({ theme })}
      value={value}
      onChange={handleChange}
      aria-label="top-navbar"
    >
      {links.map((link) => (
        <MuiTab
          key={link.label}
          label={link.label}
          value={link.value}
          onClick={link.handleClick}
          disabled={link.disabled}
          {...tabProps(link.label)}
        />
      ))}
    </MuiTabs>
  )
}
