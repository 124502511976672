import { css } from '@emotion/react'
import { Theme } from '@mui/material'
import Color from 'colorjs.io'

import { SwatchItem, SwatchSize, SwatchStyleWeight } from './Swatch'

type coordinate = { x: number; y: number }

function isColorTooLight(rgba: string | undefined) {
  if (rgba == undefined) return true
  const color_obj = new Color(rgba)
  const lightness = color_obj.lch.l
  const alpha = color_obj.alpha
  return lightness > 90 || alpha < 0.2
}
function isItemTooLight(item: SwatchItem) {
  return isColorTooLight(item.color) && isColorTooLight(item.secondary_color)
}
function getDarkenedColor(rgba: string | undefined) {
  if (rgba == undefined) return new Color('#BBB')
  const color_obj = new Color(rgba)
  color_obj.darken(0.1)
  color_obj.alpha += 0.4
  return color_obj
}

const getSwatchSize = (size: SwatchSize | undefined) => {
  if (size == undefined) size = 'medium'
  switch (size) {
    case 'small':
      return 16
    case 'medium':
      return 20
    case 'large':
      return 24
  }
}

const getSwatchSizePx = (size: SwatchSize | undefined) => {
  return getSwatchSize(size) + 'px'
}

const getStripeCount = (weight: SwatchStyleWeight | undefined) => {
  return weight != undefined
    ? {
        light: 6.5,
        normal: 5.5,
        heavy: 4.5,
      }[weight]
    : 5.5
}

export const fillMainClass = (
  item: SwatchItem,
  theme: Theme,
  isTop: boolean,
  isBottom: boolean,
) => {
  const darkened_color = getDarkenedColor(item.color)
  const topRadius = isTop ? '4px' : '0px'
  const bottomRadius = isBottom ? '4px' : '0px'

  let border = ''
  if (isItemTooLight(item))
    border = `
    ${isTop ? `border-top: 1px solid ${darkened_color};` : ''}
    border-left: 1px solid ${darkened_color};
    border-right: 1px solid ${darkened_color};
    ${isBottom ? `border-bottom: 1px solid ${darkened_color};` : ''}
  `

  return css`
    width: ${getSwatchSizePx(item.size)};
    overflow: hidden;
    background-color: ${item.color};
    display: inline-block;
    margin: 0 ${theme.spacing(1.2)} 0 0;
    flex-shrink: 0;
    border-radius: ${topRadius} ${topRadius} ${bottomRadius} ${bottomRadius};
    ${border}
    position: relative;

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `
}

export const swatchLineParent = (item: SwatchItem, theme: Theme) => {
  const darkened_color = getDarkenedColor(item.color)

  let background = ''
  if (isColorTooLight(item.color)) background = `background-color: ${darkened_color};`

  return css`
    width: ${getSwatchSizePx(item.size)};
    min-height: ${getSwatchSizePx(item.size)};
    display: inline-block;
    margin: 0 ${theme.spacing(1.2)} 0 0;
    flex-shrink: 0;
    position: relative;
    ${background}

    > svg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  `
}

export const fillPathDasharray = (item: SwatchItem) => {
  const ss = 24
  const stripes = getStripeCount(item.weight)
  // const weight = 0 // TODO: do we need this?
  switch (item.style) {
    case 'dotted': {
      return 0 + ',' + ss / stripes
    }
  }
  return ''
}

export const fillDashoffset = (item: SwatchItem) => {
  const ss = getSwatchSize(item.size) - 1
  switch (item.style) {
    case 'dotted': {
      return ss / 6
    }
  }
  return 0
}

export const fillStrokeLinecap = (item: SwatchItem) => {
  switch (item.style) {
    case 'dotted': {
      return 'round'
    }
  }
  return 'butt'
}

export const lineStrokeLinecap = (item: SwatchItem) => {
  switch (item.style) {
    case 'dotted': {
      return 'round'
    }
  }
  return 'butt'
}

function generatePathPattern(coords: coordinate[], offset: coordinate, repeats = 10) {
  let path = `M${coords[0].x} ${coords[0].y} l${coords[1].x} ${coords[1].y} `
  for (let v = 0; v < repeats - 1; v++) {
    path += `m${offset.x - coords[1].x} ${offset.y - coords[1].y} l${coords[1].x} ${coords[1].y} `
  }
  return path
}

export const fillPathD = (item: SwatchItem) => {
  const ss = 24
  const stripes = getStripeCount(item.weight)
  switch (item.style) {
    case 'diagonal_stripes': {
      return generatePathPattern(
        [
          { x: 0, y: -ss },
          { x: ss, y: ss },
        ],
        { x: 0, y: ss / (stripes - 2) },
      )
    }
    case 'vertical_stripes': {
      return generatePathPattern(
        [
          { x: ss / stripes / 2, y: 0 },
          { x: 0, y: ss },
        ],
        { x: ss / stripes, y: 0 },
        stripes,
      )
    }
    case 'horizontal_stripes': {
      return generatePathPattern(
        [
          { x: 0, y: ss / stripes / 2 },
          { x: ss, y: 0 },
        ],
        { x: 0, y: ss / stripes },
        stripes,
      )
    }
    case 'dotted': {
      return (
        generatePathPattern(
          [
            { x: 0, y: ss / stripes / 2 },
            { x: ss, y: 0 },
          ],
          { x: 0, y: (ss / stripes) * 2 },
          stripes,
        ) +
        generatePathPattern(
          [
            { x: -ss / stripes / 2, y: ss / stripes / 2 + ss / stripes },
            { x: ss, y: 0 },
          ],
          { x: 0, y: (ss / stripes) * 2 },
          stripes,
        )
      )
    }
    case 'hashed': {
      return (
        generatePathPattern(
          [
            { x: 0, y: 0 },
            { x: ss, y: -ss },
          ],
          { x: 0, y: ss / (stripes - 1) },
        ) +
        generatePathPattern(
          [
            { x: 0, y: -ss },
            { x: ss, y: ss },
          ],
          { x: 0, y: ss / (stripes - 1) },
        )
      )
    }
    case 'checked': {
      return (
        generatePathPattern(
          [
            { x: 0, y: ss / stripes / 2 },
            { x: ss, y: 0 },
          ],
          { x: 0, y: ss / stripes },
          stripes,
        ) +
        generatePathPattern(
          [
            { x: ss / stripes / 2, y: 0 },
            { x: 0, y: ss },
          ],
          { x: ss / stripes, y: 0 },
          stripes,
        )
      )
    }
  }
}

export const linePathD = (item: SwatchItem) => {
  return `M0 ${getSwatchSize(item.size) / 2} l${getSwatchSize(item.size)} 0`
}

export const linePathDasharray = (item: SwatchItem) => {
  let dash_width
  let gap_width
  const ss = getSwatchSize(item.size) - 1

  switch (item.style) {
    case 'normal':
      return ''
    case 'dashed':
      {
        dash_width = (ss / 13) * 5
        gap_width = (ss / 13) * 3
      }
      break
    case 'dotted':
      {
        dash_width = 0
        gap_width = ss / 3
      }
      break
  }

  return dash_width + ',' + gap_width
}

export const lineDashoffset = (item: SwatchItem) => {
  const ss = getSwatchSize(item.size) - 1
  switch (item.style) {
    case 'dotted': {
      return ss / 6
    }
  }
  return 0
}

export const swatchIconParent = (item: SwatchItem, theme: Theme) => {
  return css`
    padding: 0;
    margin: 0 ${theme.spacing(1.2)} 0 0;
    width: ${getSwatchSizePx(item.size)};
    min-height: ${getSwatchSizePx(item.size)};
  `
}
