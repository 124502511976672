import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

interface topNavbarContainerProps {
  theme: Theme
}

export const topNavbarContainer = ({ theme }: topNavbarContainerProps) => css`
  box-shadow: ${theme.shadows[1]};
  width: 100%;

  padding: 0 ${theme.spacing(4)};

  & button {
    letter-spacing: 'normal';
    font-size: ${theme.spacing(3.5)};
    margin: 0 ${theme.spacing(1)};

    &:disabled {
      color: ${theme.palette.text.disabled};
    }
  }
`
