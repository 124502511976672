import { css } from '@emotion/react'
import { Theme } from '@mui/material/styles'

export const sideNavigationButtonContainer = ({
  theme,
  active = false,
  disabled = false,
}: {
  theme: Theme
  active: boolean
  disabled: boolean
}) => css`
  display: flex;
  align-items: center;
  justify-content: start;

  gap: ${theme.spacing(2)};

  padding: ${theme.spacing(2)};
  border-radius: ${theme.shape.borderRadius}px;

  border: none;

  background-color: transparent;
  background-color: ${active && theme.palette.primary.main};

  color: ${theme.palette.text.primary};
  color: ${active && theme.palette.white.main};
  color: ${disabled && theme.palette.grey[300]};

  transition: background-color 0.2s;

  &:hover {
    cursor: pointer;
    cursor: ${disabled && 'not-allowed'};
    background-color: ${!active && !disabled && theme.palette.primary.main + '10'};
  }
`

export const sideNavigationTitle = ({ theme }: { theme: Theme }) => css`
  font-weight: 700;
  padding: ${theme.spacing(3)} 0;
  color: ${theme.palette.primary.main};
  border-bottom: 1px solid ${theme.palette.grey[200]};
`

export const sideNavigationContainer = ({ theme }: { theme: Theme }) => css`
  height: 100%;
  width: 240px;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  padding: ${theme.spacing(2)};
  background-color: ${theme.palette.white.main};

  border-right: 1px solid ${theme.palette.grey[200]};
  box-shadow: ${theme.shadows[1]};

  z-index: 10;
`

export const sideNavigationButtonsContainer = ({ theme }: { theme: Theme }) => css`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing(2)};
  gap: ${theme.spacing(1)};
`
