/** @jsxImportSource @emotion/react */
import '@routes/App/App.css'

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  createTheme,
  css,
  Typography,
} from '@mui/material'
import { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { useFeatureFlags } from '@contexts/FeatureFlagsContext'

import { RootState } from '@redux/store'
import { appContainer, appContentContainer, sidebarContainer } from '@routes/App/App.styles'
import { getSidebarMiddleMenuLinks } from '@routes/App/App.utils'
import { secondaryTextPrimary } from '../LivePage/LivePage.styles'
import { AppAuthHolder } from '@src/components/Molecules/AppAuthHolder/AppAuthHolder'
import { Sidebar, SidebarMenuButton } from '@src/components/Organisms/Sidebar'
import { updateUserStateProperty } from '@redux/user/userSlice'
import { theme } from '@styles/theme'
import { CustomThemeProvider } from '@src/components/Atoms/CustomThemeProvider'

export async function loader() {
  const data = setTimeout(() => {
    return 'finish'
  }, 0)
  return data
}
export const ErrorPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { features } = useFeatureFlags()
  const { pathname } = useLocation()

  const userStore = useSelector((state: RootState) => state.user)
  const {
    clientDisplayName,
    clientName,
    theme: storeTheme,
    logos: storeLogo,
    feedback_url,
  } = userStore

  const [authModal, setAuthModal] = useState(false)
  const [logoutDialog, setLogoutDialog] = useState(false)

  const isUserLoggedIn = !!clientName.length

  const clientTheme = useMemo(
    () =>
      createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: {
            ...theme.palette.primary,
            ...storeTheme.primary,
          },
          secondary: {
            ...theme.palette.secondary,
            ...storeTheme.secondary,
          },
        },
      }),
    [storeTheme],
  )

  const sidebarMiddleMenu = getSidebarMiddleMenuLinks({
    pathname,
    navigate,
    featuresFlags: features,
  })

  const sidebarBottomMenuWithoutUser: SidebarMenuButton[] = [
    {
      label: clientName ? 'Logout' : 'Login',
      icon: clientName ? 'SignOut' : 'SignIn',
      active: false,
      onClick: () => setAuthModal(true),
    },
  ]

  const sidebarBottomMenu: SidebarMenuButton[] = [
    {
      label: clientDisplayName,
      icon: 'People',
      active: false,
      onClick: () => setAuthModal(true),
    },
    ...sidebarBottomMenuWithoutUser,
  ]

  return (
    <CustomThemeProvider theme={clientTheme}>
      <Box css={appContainer}>
        <Box css={sidebarContainer}>
          <Sidebar
            logos={[
              {
                title: clientDisplayName,
                reducedLogo: storeLogo.whiteSimple,
                fullLogo: storeLogo.whiteFull,
                logoBackgroundColour: 'transparent',
                onClick: () => navigate('/'),
              },
            ]}
            feedback_url={feedback_url}
            middleMenu={sidebarMiddleMenu}
            bottomMenu={isUserLoggedIn ? sidebarBottomMenu : sidebarBottomMenuWithoutUser}
            appVersion={APP_VERSION}
            setShowFeedbackModal={(show: boolean) =>
              dispatch(updateUserStateProperty({ show_feedback_modal: show }))
            }
          />
        </Box>

        <Box css={appContentContainer}>
          <Box
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              height: 100vh;
              background-color: ${clientTheme.palette.primary.main}40;
            `}
          >
            <Card sx={{ width: '60vw', maxWidth: '800px', maxHeight: '100%', overflow: 'auto' }}>
              <CardMedia
                sx={{ height: '50vh', backgroundSize: 'contain' }}
                image="./images/illustrations/404NotFound.svg"
                title="Example of the live screen"
              />
              <CardContent>
                <Typography variant="h2" component="div" css={secondaryTextPrimary({ theme })}>
                  An unexpected error has occurred
                </Typography>
                <Typography variant="body1">
                  Apologies for the inconvenience. Please report this issue and try again later.
                </Typography>
              </CardContent>
              <CardActions sx={{ padding: theme.spacing(4) }}>
                <Button
                  size="medium"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  sx={{
                    marginBottom: theme.spacing(2),
                  }}
                  onClick={() => dispatch(updateUserStateProperty({ show_feedback_modal: true }))}
                >
                  Submit Feedback
                </Button>
              </CardActions>
            </Card>
          </Box>
          <Box></Box>
        </Box>
      </Box>
      {createPortal(
        <AppAuthHolder
          showLogoutDialog={logoutDialog}
          handleLogoutDialogClose={() => setLogoutDialog(false)}
          handleOpen={() => setAuthModal(true)}
          handleClose={() => setAuthModal(false)}
          open={authModal}
        />,
        document.body,
      )}
    </CustomThemeProvider>
  )
}
