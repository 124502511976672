/** @jsxImportSource @emotion/react */
import { Box, Divider, IconButton, Tab, Tabs } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { useTheme } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { SyntheticEvent, useCallback, useEffect, useState } from 'react'

import LogoFull from '@assets/logos/UILogoFull.svg'
import { useMap } from '@contexts/MapContext'
import { RootState } from '@redux/store'
import {
  Client,
  setUserRole,
  UserTheme,
  UserLogos,
  setUserClientWelcomeSidebarContent,
  setUserAvailableClients,
  setupUserState,
  setShowChangelog,
  GetUserResponse,
  ClientTerrainMap,
  setUserEmail,
} from '@redux/user/userSlice'
import { BASE_API_URL } from '@src/app-constants'
import { Modal } from '@src/components/Atoms/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { ForgotPassword } from './ForgotPassword'
import { LoginTab } from './LoginTab'
import {
  modalLogo,
  signInModalContainer,
  signInSpinner,
  stepperDivider,
  tabFocus,
} from './SignInModal.styles'
import { SignUpTab } from './SignUpTab/SignUpTab'
import axios from '@src/utils/customAxios'
import { ChangePassword } from './ChangePassword'
import { useCookies } from 'react-cookie'
import { Close } from '@mui/icons-material'
import { MapLocation } from '@redux/map/mapSlice'
import { datadogRum } from '@datadog/browser-rum'
import { useSnackbars } from '@contexts/SnackbarContext'

interface SignInModalProps {
  open: boolean
  isAuth: boolean
  setIsAuth: (isAuth: boolean) => void
  handleClose: () => void
  handleLogout: () => void
}

export interface PasswordProps {
  value: string
  error: boolean
  helperTexts: string[]
}

export interface ValidationInputProps {
  value: string
  error: boolean
  helperText: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`login-modal-tabpanel-${index}`}
      aria-labelledby={`login-modal-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  )
}

export function LoadingButton() {
  const theme = useTheme()
  return <CircularProgress size={28} css={signInSpinner({ theme })} />
}

const publicUserPool: Client = {
  client_name: 'openaccess',
  display_name: 'Open Access',
  accepted_tsncs: false,
}

const initialTextInput: ValidationInputProps = {
  value: '',
  error: false,
  helperText: '',
}

const initialPassword: PasswordProps = {
  value: '',
  error: false,
  helperTexts: [],
}

export interface ClientConfigResponse {
  theme: UserTheme
  logos: UserLogos
  logoFolder: string
  tsncs_html: string | null
  feedback_url: string
  terrain_map: ClientTerrainMap
  default_location: MapLocation
  methods_report_url: string
}

export const SignInModal = ({
  handleClose,
  open,
  isAuth,
  setIsAuth,
  handleLogout,
}: SignInModalProps) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const [cookies, setCookie, removeCookie] = useCookies(['client_name', 'explorerApiSession'])

  const { map, clearMap, updateLayers, updateDrawAreas, handleSetClientLocation } = useMap()

  const { clientName } = useSelector((state: RootState) => state.user)
  const { layers, drawAreas } = useSelector((state: RootState) => state.map)

  const { displaySnackbar } = useSnackbars()
  const [tab, setTab] = useState(0)
  const [activeStep, setActiveStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const [userPools, setUserPools] = useState<Client[]>([publicUserPool])
  const [client, setClient] = useState<Client | null>(null)

  const [userUUID, setUserUUID] = useState<string | null>(null)
  const [authChallengeSessionId, setAuthChallengeSessionId] = useState<string | null>(null)
  const [email, setEmail] = useState(initialTextInput)
  const [phone_number, setPhoneNumber] = useState(initialTextInput)
  const [password, setPassword] = useState<PasswordProps>(initialPassword)
  const [confirmPassword, setConfirmPassword] = useState<PasswordProps>(initialPassword)
  const [newPassword, setNewPassword] = useState<PasswordProps>(initialPassword)
  const [forgotPassword, setForgotPassword] = useState(false)
  const [changePassword, setChangePassword] = useState(false)
  const [validationCode, setValidationCode] = useState(initialTextInput)
  const [preloadSignup, setPreloadSignup] = useState<
    { email: string; password: string } | undefined
  >(undefined)

  // const isUserLoggedIn = !!clientName || !!cookies['explorerApiSession']
  const isUserLoggedIn = !!clientName && cookies['client_name'] !== 'openaccess' && isAuth

  const apiGetClientConfig = useCallback(async () => {
    try {
      const clientConfigResponse = await axios.get<ClientConfigResponse>(
        `${BASE_API_URL}/api/client/config`,
      )
      return clientConfigResponse.data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: `, error)
      return null
    }
  }, [])

  const apiGetClientWelcomeSidebarContent = useCallback(async () => {
    try {
      const clientSidebarContentResponse = await axios.get<Record<string, unknown>>(
        `${BASE_API_URL}/api/client/welcome_sidebar_content`,
      )
      return clientSidebarContentResponse.data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: `, error)
      return null
    }
  }, [])

  const apiGetAvailableUserPools = useCallback(async () => {
    setIsLoading(true)

    try {
      const clientResponse = await axios.get<
        { client_name: string; display_name: string; accepted_tsncs: boolean }[]
      >(`${BASE_API_URL}/api/client`)

      const availableUserPools: Client[] = clientResponse.data.map((client) => ({
        client_name: client.client_name,
        display_name: client.display_name,
        accepted_tsncs: client.accepted_tsncs,
      }))

      if (!availableUserPools.length) {
        setUserPools([])
        setClient(publicUserPool)
        displaySnackbar({
          message: 'Login successful.',
          type: 'success',
        })
        handleClose()
        return availableUserPools
      }

      return availableUserPools
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(`error: `, error)
      displaySnackbar({
        message: 'Something went wrong. Please check if the e-mail is valid',
        type: 'error',
      })
      return []
    } finally {
      setIsLoading(false)
    }
  }, [displaySnackbar, handleClose])

  const apiLogin = useCallback(async () => {
    setIsLoading(true)
    const userDetails = `${email.value}:${password.value}`
    const encodedUserDetails = btoa(userDetails)

    const loginResponse = await axios.post(
      `${BASE_API_URL}/api/user/login`,
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Basic ${encodedUserDetails}`,
        },
        validateStatus: () => true, // This stops axios from throwing errors on non-200 responses
      },
    )

    const isLoginSuccessful = loginResponse.status === 200
    const isChangePasswordRequired =
      loginResponse.status === 449 && loginResponse.data === 'NEW_PASSWORD_REQUIRED'
    const isConfirmSignupRequired =
      loginResponse.status === 449 && loginResponse.data === 'CONFIRM_SIGN_UP'

    const uuidHeader = loginResponse.headers['user_uuid']
    setUserUUID(uuidHeader)

    if (isChangePasswordRequired) {
      setAuthChallengeSessionId(loginResponse.headers['auth_challenge_session'])

      displaySnackbar({
        message: 'Login Successful! But please change your password.',
        type: 'warning',
      })
      setChangePassword(true)
      setIsLoading(false)
      return false
    } else if (isConfirmSignupRequired) {
      displaySnackbar({
        message: 'Login Successful! But please confirm your signup.',
        type: 'warning',
      })
      setTab(1)
      setPreloadSignup({ email: email.value, password: password.value })
      setIsLoading(false)
      return false
    } else if (!isLoginSuccessful) {
      displaySnackbar({
        message: 'Something went wrong. Please check if the e-mail or password is valid',
        type: 'error',
      })
    }

    const userInfo = await getUser()
    dispatch(setUserRole(userInfo.user_role ?? ''))
    dispatch(setShowChangelog(userInfo.show_changelog))
    dispatch(setUserEmail(userInfo.email))
    if (uuidHeader || userInfo.email) {
      Sentry.setUser({ id: uuidHeader, email: userInfo.email })
      datadogRum.setUser({
        id: uuidHeader,
        email: userInfo.email,
        role: userInfo.user_role,
      })
    }

    setIsLoading(false)
    return isLoginSuccessful
  }, [displaySnackbar, email.value, password, dispatch])

  const handleExpiredSession = async () => {
    handleLogout()
    displaySnackbar({
      message: 'Session expired, please log in again',
      type: 'warning',
    })
  }

  const updateClientConfig = useCallback(
    async (new_client: Client | null) => {
      if (!new_client) return
      setIsLoading(true)
      const clientConfig = await apiGetClientConfig()
      const clientSidebarContent = await apiGetClientWelcomeSidebarContent()
      if (clientConfig === null) {
        displaySnackbar({
          message: 'Something went wrong. Please check if the client is valid',
          type: 'error',
        })
        setIsLoading(false)
        return
      }
      const {
        theme,
        default_location: clientLocation,
        feedback_url,
        tsncs_html,
        methods_report_url,
        terrain_map,
      } = clientConfig
      if (
        import.meta.env.VITE_ISLOCAL !== 'true' &&
        clientSidebarContent &&
        Object.keys(clientSidebarContent).length > 0
      )
        dispatch(setUserClientWelcomeSidebarContent(clientSidebarContent))
      dispatch(
        setupUserState({
          clientDisplayName: new_client.display_name,
          clientName: new_client.client_name,
          clientTerrainMap: terrain_map,
          feedback_url: feedback_url,
          theme,
          accepted_tsncs: new_client.accepted_tsncs,
          tsncs_html,
          methods_report_url,
        }),
      )
      try {
        axios.post(`${BASE_API_URL}/api/user/increment_login`)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Failed to increment_login ${error}`)
      }

      if (clientLocation)
        setTimeout(() => {
          handleSetClientLocation(clientLocation)
        }, 0)

      setIsLoading(false)
    },
    [
      apiGetClientConfig,
      apiGetClientWelcomeSidebarContent,
      dispatch,
      displaySnackbar,
      handleSetClientLocation,
    ],
  )

  // Update cookie & config with client
  const applyClient = useCallback(
    async (new_client: Client | null) => {
      if (!new_client) {
        applyClient(publicUserPool)
        Sentry.setTag('ui_client', 'openaccess')
        return
      }

      setClient(new_client)
      Sentry.setTag('ui_client', new_client.client_name)

      setCookie(`client_name`, `${new_client.client_name}`, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
      })

      // UPDATE CLIENT CONFIG
      await updateClientConfig(new_client)

      const userInfo = await getUser()
      dispatch(setUserRole(userInfo.user_role ?? ''))
      dispatch(setShowChangelog(userInfo.show_changelog))
      dispatch(setUserEmail(userInfo.email))
      if (userInfo.email) {
        Sentry.setUser({ email: userInfo.email })
        datadogRum.setUser({
          id: userInfo.user_uuid,
          email: userInfo.email,
          role: userInfo.user_role,
          client: new_client.client_name,
        })
      }

      // Remove everything from map
      // only set to empty if layers already exist
      if (layers.length > 0) updateLayers({ layers: [] })
      if (drawAreas.length > 0) updateDrawAreas({ drawAreas: [] })

      if (map) {
        clearMap({ map })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updateClientConfig, updateLayers, updateDrawAreas, map, clearMap, dispatch],
  )

  const getUser = async (): Promise<GetUserResponse> => {
    try {
      const response = await axios.get(`${BASE_API_URL}/api/user`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return response.data
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      return {
        user_role: '',
        show_changelog: false,
        email: '',
        user_uuid: '',
        client_name: '',
        accepted_tsncs: false,
        date_created: '',
        last_logged_in: '',
        logged_in_count: 0,
        status: '',
      }
    }
  }

  // Used after both login and signup
  const updateAvailableClients = useCallback(async () => {
    const availableUserPools = await apiGetAvailableUserPools()
    setUserPools(availableUserPools)

    return availableUserPools
  }, [apiGetAvailableUserPools])

  // USED AFTER THE LOGIN TO AWS IS ALREADY SUCCESSFUL AND COMPLETE
  const applyLogin = useCallback(async () => {
    const available_clients = await updateAvailableClients()

    if (!available_clients) {
      setIsLoading(false)
      return
    }
    dispatch(setUserAvailableClients(available_clients))

    if (available_clients.length === 1) {
      await applyClient(available_clients[0])
    } else {
      const foundClient = available_clients.find((c) => c.client_name === cookies['client_name'])
      if (foundClient) await applyClient(foundClient)
      else await applyClient(available_clients[1])
    }
    setIsAuth(true)
    handleClose()
  }, [applyClient, updateAvailableClients, cookies, dispatch, handleClose, setIsAuth])

  // ATTEMPTS LOGIN TO AWS
  const attemptLogin = useCallback(async (): Promise<boolean> => {
    if (!isUserLoggedIn) {
      const isLoginSuccessful = await apiLogin()
      return isLoginSuccessful
    }
    return false
  }, [apiLogin, isUserLoggedIn])

  // First stage of Login
  const handleLoginSubmit = useCallback(async () => {
    setIsLoading(true)
    setUserPools([])

    if (await attemptLogin()) {
      await applyLogin()
    }
  }, [attemptLogin, applyLogin])

  // Second stage of Login - having picked a client
  const handleLoginWithClientConfig = useCallback(async () => {
    if (!client?.display_name.length) {
      setIsLoading(false)
      return
    }

    displaySnackbar({
      message: 'Login successful.',
      type: 'success',
    })
    setIsLoading(false)
    handleClose()
  }, [displaySnackbar, handleClose, client?.display_name])

  const tabProps = (index: number) => {
    return {
      id: `login-modal-tab-${index}`,
      'aria-controls': `login-modal-tabpanel-${index}`,
    }
  }

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  useEffect(() => {
    setEmail({ ...email, error: false, helperText: '' })
    setPassword({ ...password, error: false, helperTexts: [] })
    setConfirmPassword({ ...confirmPassword, error: false, helperTexts: [] })
    setValidationCode({ ...validationCode, error: false, helperText: '' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, forgotPassword, tab])

  // add event listener to login if enter is pressed
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (tab === 0 && !forgotPassword && !changePassword) {
          handleLoginSubmit()
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [changePassword, forgotPassword, handleLoginSubmit, handleLoginWithClientConfig, tab])

  // request logic for getting available clients on subsequent modal opens if user is logged in
  useEffect(() => {
    if (isUserLoggedIn && activeStep === 0 && userPools.length < 2) {
      updateAvailableClients()
    }
  }, [isUserLoggedIn, activeStep, userPools.length, updateAvailableClients])

  // useEffect only for local development purposes
  useEffect(() => {
    if (
      import.meta.env.VITE_ISLOCAL === 'true' &&
      import.meta.env.VITE_LOCAL_USERNAME &&
      import.meta.env.VITE_LOCAL_PASSWORD &&
      (!email.value || !password.value)
    ) {
      if (!email.value)
        setEmail({ value: import.meta.env.VITE_LOCAL_USERNAME, error: false, helperText: '' })
      if (!password.value)
        setPassword({ value: import.meta.env.VITE_LOCAL_PASSWORD, error: false, helperTexts: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Run after user has changed password
  const handlePasswordChanged = useCallback(async () => {
    setChangePassword(false)
    applyLogin()
  }, [applyLogin])

  // useEffect only for local development purposes
  useEffect(() => {
    if (
      import.meta.env.VITE_ISLOCAL === 'true' &&
      email.value &&
      password.value &&
      !isUserLoggedIn
    ) {
      handleLoginSubmit()
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email.value, password.value])

  useEffect(() => {
    if (isAuth) {
      applyLogin()
        .then(() => {
          handleClose()
        })
        .catch(() => {
          handleExpiredSession()
          removeCookie('explorerApiSession')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth])

  const stepProps = {
    isLoading: isLoading,
    email: email,
    password: password,
    handleChangeEmail: setEmail,
    handleChangePassword: setPassword,
    handleChangePhoneNumber: setPhoneNumber,
    phone_number: phone_number,
  }

  return (
    <Modal open={open} onClose={() => handleClose()}>
      <Box css={signInModalContainer({ theme })}>
        <IconButton
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
          }}
          onClick={() => handleClose()}
        >
          <Close />
        </IconButton>
        <Box component="img" src={LogoFull} css={modalLogo({ theme })} />
        <Divider css={stepperDivider({ theme })} />
        {forgotPassword ? (
          <ForgotPassword
            {...stepProps}
            handleClose={handleClose}
            validationCode={validationCode}
            forgotPassword={forgotPassword}
            confirmPassword={confirmPassword}
            handleSetLoading={setIsLoading}
            handleChangeCode={setValidationCode}
            handleChangeConfirmPassword={setConfirmPassword}
            handleChangeForgotPassword={setForgotPassword}
          />
        ) : changePassword ? (
          <ChangePassword
            {...stepProps}
            userUUID={userUUID}
            authChallengeSessionId={authChallengeSessionId}
            handlePasswordChanged={handlePasswordChanged}
            validationCode={validationCode}
            newPassword={newPassword}
            confirmPassword={confirmPassword}
            handleSetLoading={setIsLoading}
            handleChangeCode={setValidationCode}
            handleChangeNewPassword={setNewPassword}
            handleChangeConfirmPassword={setConfirmPassword}
          />
        ) : (
          <Box>
            <>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  aria-label="Login modal tabs"
                  variant="fullWidth"
                >
                  <Tab label="Log in" {...tabProps(0)} css={tabFocus({ theme })} />
                  <Tab label="Sign up" {...tabProps(1)} css={tabFocus({ theme })} />
                </Tabs>
              </Box>
              <CustomTabPanel value={tab} index={0}>
                <LoginTab
                  {...stepProps}
                  activeStep={activeStep}
                  organization={client}
                  userPools={userPools}
                  forgotPassword={forgotPassword}
                  handleChangeStep={setActiveStep}
                  applyClient={applyClient}
                  handleChangeActiveStep={setActiveStep}
                  handleChangeForgotPassword={setForgotPassword}
                  handleLoginSubmit={handleLoginSubmit}
                  handleLoginWithClientConfig={handleLoginWithClientConfig}
                  handleLogout={handleLogout}
                />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <SignUpTab
                  {...stepProps}
                  attemptLogin={attemptLogin}
                  handleClose={handleClose}
                  preloadSignup={preloadSignup}
                  validationCode={validationCode}
                  confirmPassword={confirmPassword}
                  handleSetLoading={setIsLoading}
                  handleChangeCode={setValidationCode}
                  handleChangeConfirmPassword={setConfirmPassword}
                  applyLogin={applyLogin}
                />
              </CustomTabPanel>
            </>
          </Box>
        )}
      </Box>
    </Modal>
  )
}
