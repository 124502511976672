/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import * as MuiTab from '@mui/material/Tab'
import * as MuiTabs from '@mui/material/Tabs'
import { useState } from 'react'

import { TabContent } from './TabContent'
import { tabsContainer, tabsMenuContainer } from './Tabs.styles'
import { getTabMenuProps } from './Tabs.utils'

export interface Tab {
  label: string | JSX.Element | React.ReactNode
  content: JSX.Element | React.ReactNode
  disabled?: boolean
}

export interface TabsProps {
  tabs: Tab[]
  className?: string
  tabIndex?: number
  handleChangeTab?: (newValue: number) => void
}

export const Tabs = ({ tabs, className, tabIndex, handleChangeTab }: TabsProps) => {
  const theme = useTheme()
  const [localTabIndex, setLocalTabIndex] = useState(tabIndex ?? 0)

  return (
    <Box css={tabsContainer({ theme })} className={className}>
      <MuiTabs.default
        variant="scrollable"
        css={tabsMenuContainer({ theme })}
        value={tabIndex ?? localTabIndex}
        onChange={(_e, newValue) =>
          handleChangeTab ? handleChangeTab(newValue) : setLocalTabIndex(newValue)
        }
        allowScrollButtonsMobile
        scrollButtons
      >
        {tabs.map(({ label, disabled = false }, index) => (
          <MuiTab.default
            key={index}
            label={label}
            disabled={disabled}
            {...getTabMenuProps(index, theme)}
          />
        ))}
      </MuiTabs.default>

      {tabs.map(({ content }, index) => (
        <TabContent
          key={index}
          index={index}
          tabIndex={tabIndex ?? localTabIndex}
          css={css`
            width: 100%;
            height: calc(100% - 49px);
          `}
        >
          {content}
        </TabContent>
      ))}
    </Box>
  )
}
