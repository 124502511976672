/** @jsxImportSource @emotion/react */
import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Icon } from '@src/components/Atoms/Icon'
import { getDivisionIconByString, getIconColour } from '@src/utils/icons.utils'

import { sideNavigationButtonContainer } from './SideNavigationButton.styles'

export interface SideNavigationButtonProps {
  title: string
  icon: Icon
  active?: boolean
  disabled?: boolean
  handleClick?: () => void
}

export const SideNavigationButton = ({
  title,
  icon,
  active = false,
  disabled = false,
  handleClick = () => undefined,
}: SideNavigationButtonProps) => {
  const theme = useTheme()

  return (
    <Box
      component="button"
      css={sideNavigationButtonContainer({ theme, active, disabled })}
      onClick={handleClick}
      disabled={disabled}
    >
      {getDivisionIconByString({
        iconName: icon,
        size: 'large',
        colour: getIconColour({ active, disabled }),
      })}
      <Typography variant="body1">{title}</Typography>
    </Box>
  )
}
